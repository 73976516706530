import React, { Component } from 'react';
// import '../Schedule.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Checkbox, Input, Typography, Switch, Select, Button, Tooltip } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { getScheduleAction, getKypProjectsAction, refreshKypProjectDataAction, syncKypScheduleForOccupantsAction, disconnectFromKypAction } from '../../../../../actions/scheduleActions';
import { getProjectAction } from '../../../../../actions/projectActions';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { bind } from 'lodash';
import { showSuccess } from '../../../../../utils/Notifications';

const { TextArea } = Input;
const { Title, Text, Paragraph } = Typography;

class Settings extends Component
{
  state = {
    schedule: {},
    image_id: false,
    filelist: [],
    loading: true,
  }

  componentDidMount()
  {
    this.load();
  }

  load()
  {
    this.setState({ loading: true });

    this.props.getProjectAction(this.props.match.params.id).then(() => {

      this.props.getScheduleAction(this.props.match.params.id).then(() => {

        if(this.props.customer.use_kyp && this.props.project.has_kyp_access_token)
        {
            this.props.getKypProjectsAction(this.props.project.id);

            if(this.props.schedule.use_kyp_schedule)
            {
                this.props.useKyp(true);
            }
        }

        this.setState({
          use_kyp_schedule: this.props.customer.use_kyp == true ? (this.props.schedule.use_kyp_schedule ? true : false) : false,
          kyp_project_id: this.props.project.kyp_project_id,
          image_id: this.props.schedule.image_id,
          loading: false
        });

      });
    });
  }

  connectWithKyp()
  {
    window.sessionStorage.setItem('project_id_for_kyp', this.props.project.id);
    window.sessionStorage.setItem('kyp_return_url', window.location.href);

    window.location = this.props.customer.kyp_login_url;
  }

  disconnectFromKyp()
  {
    this.props.disconnectFromKypAction(this.props.project.id).then((status) => {

      if(status)
      {
        showSuccess();
      }

      this.load();
    });
  }

  refreshKypProjectData()
  {
    this.props.refreshKypProjectDataAction(this.props.project.id).then((status) => {

      if(status)
      {
        showSuccess();
      }

      this.load();
    });
  }

  syncKypScheduleForOccupants()
  {
    this.props.syncKypScheduleForOccupantsAction(this.props.project.id).then((status) => {

      if(status)
      {
        showSuccess();
      }

      this.load();
    });
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    if(this.state.loading){ return null; }

    return (
      <Form layout={'vertical'} onSubmit={this.props.onSubmit}>

        { this.props.customer.use_kyp == true && this.props.customer.kyp_login_url ? <div>
          <Row className="mbm">
            <Col span={24}>
              <Title level={4} style={{ margin: 0 }}>KYP</Title>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
                <Form.Item label="Gebruik KYP:">
                  {getFieldDecorator('use_kyp_schedule', {
                    valuePropName: 'checked',
                    initialValue: this.state.use_kyp_schedule ? true : false,
                    onChange: (checked) => {
                        this.setState({ use_kyp_schedule: checked }, () => { this.props.useKyp(this.state.use_kyp_schedule ? true : false); });
                    }
                  })(
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
            </Col>
          </Row>

          { this.state.use_kyp_schedule === true ? <Row gutter={24}>
            <Col span={24}>
              { !this.props.project.has_kyp_access_token ? <Form.Item label={"Koppel een KYP account aan dit project:"}>
                <Button onClick={() => { this.connectWithKyp(); }}>{'Koppelen aan KYP'}</Button>
                <Tooltip className="mhs" overlayStyle={{ whiteSpace: 'pre-line' }} title={"Door op de \"Koppelen aan KYP\" knop te klikken word je tijdelijk doorgestuurd naar de KYP omgeving. Daar kan je inloggen met je KYP gegevens.\r\n\r\n\
                  Wanneer je vervolgens succesvol bent ingelogd bij KYP kan een koppeling worden opgezet tussen Cobee en KYP.\r\n\r\n\
                  Vervolgens, als de koppeling succesvol tot stand is gebracht, word je automatisch teruggestuurd naar deze pagina.\r\n\r\n\
                  Je kan deze knop gebruiken om met een ander KYP account in te loggen om vervolgens die gegevens te gebruiken voor de KYP koppeling met dit Cobee project."}>
                  <InfoCircleTwoTone />
                </Tooltip>
              </Form.Item>
              :
              <Form.Item label={"Ontkoppel dit project van KYP:"}>
                <Button onClick={() => { this.disconnectFromKyp(); }}>{'Ontkoppelen van KYP'}</Button>
              </Form.Item>}
            </Col>
          </Row> : null }

          { this.state.use_kyp_schedule && this.props.project.has_kyp_access_token ? <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Koppel dit Cobee project aan het onderstaande KYP project:">
                {getFieldDecorator('kyp_project_id', {
                  initialValue: (this.props.project.kyp_project_id ? ''+this.props.project.kyp_project_id : null), // 'Selecteer een KYP project'),
                  onChange: (value) => {
                      this.setState({ kyp_project_id: ''+value });
                  }
                })(
                <Select 
                  style={{ width: 'auto', minWidth: 200 }}
                  placeholder={'Selecteer een KYP project'}
                  >
                    {/*<Select.Option value={''}>Selecteer een KYP project</Select.Option>*/}
                    { this.props.kyp_projects && this.props.kyp_projects.map && this.props.kyp_projects.map((kyp_project) => {
                        return <Select.Option key={''+kyp_project.id} value={''+kyp_project.id}>{kyp_project.name}</Select.Option>
                    }) }
                </Select>
                )}
              </Form.Item>
            </Col>
          </Row> : null }

          { this.state.use_kyp_schedule && this.props.project.has_kyp_access_token ? <Row gutter={24}>
            <Col span={24}>
                <Form.Item label={<span>
                    Automatisch publiceren voor bewoners:
                    <Tooltip className="mhs" overlayStyle={{ whiteSpace: 'pre-line' }} title={"Met deze optie kan je aangeven of de planning in KYP automatisch doorgezet mag worden naar de bewoners."}>
                      <InfoCircleTwoTone />
                    </Tooltip>
                  </span>}>
                  {getFieldDecorator('update_kyp_schedule_occupant_automatic', {
                    valuePropName: 'checked',
                    initialValue: this.props.schedule.update_kyp_schedule_occupant_automatic ? true : false,
                    onChange: (checked) => {
                        this.setState({ update_kyp_schedule_occupant_automatic: checked }, () => { /*this.props.useKyp(this.state.use_kyp_schedule ? true : false);*/ });
                    }
                  })(
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
            </Col>
          </Row> : null }

          { this.state.use_kyp_schedule && this.props.project.has_kyp_access_token && this.props.project.kyp_project_id ? <Row gutter={24}>
            <Col span={24}>
              <Button style={{ marginRight: 12 }} onClick={() => { this.syncKypScheduleForOccupants(); }}>Planning doorzetten naar bewoners</Button>
              (laatst bijgewerkt: {this.props.schedule.kyp_schedule_occupant_updated_at ? moment(this.props.schedule.kyp_schedule_occupant_updated_at).locale('NL').format('DD-MM-YYYY HH:mm:ss') : 'nooit'})
            </Col>
          </Row> : null }

        </div> : null }

        { !this.state.use_kyp_schedule ? <div>
            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Werkdagen</Title>
              </Col>
              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('monday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.monday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Maandag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('tuesday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.tuesday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Dinsdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('wednesday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.wednesday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Woensdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('thursday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.thursday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Donderdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('friday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.friday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Vrijdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('saturday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.saturday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Zaterdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('sunday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.sunday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Zondag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>

                <Form.Item label="Opmerkingen" hasFeedback>
                  {getFieldDecorator('comment', {
                    initialValue: this.props.schedule && this.props.schedule.comment,
                  })(
                    <TextArea rows={4} />
                  )}
                </Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item label="Afbeelding">
                  <AddFromMedia
                    projectId={this.props.match.params.id}
                    initialMediaId={this.props.schedule && this.props.schedule.image_id}
                    initialMediaUrl={this.props.schedule && this.props.schedule.image_url}
                    onMediaSelection={(item) => this.setState({image_id: item.id})}
                    onMediaRemove={() => this.setState({image_id: null})}
                  />

                </Form.Item>
              </Col>
            </Row>
        </div> : null }
      </Form>
    );
  }
}

const SettingsPage = Form.create({ name: 'data_form' })(Settings);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.schedule.isFetching,
    isError: state.schedule.isError,
    schedule: state.schedule.schedule,
    kyp_projects: state.schedule.kyp_projects,
    customer: state.auth.selectedCustomer,
    project: state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScheduleAction: bindActionCreators(getScheduleAction, dispatch),
    getKypProjectsAction: bindActionCreators(getKypProjectsAction, dispatch),
    refreshKypProjectDataAction: bindActionCreators(refreshKypProjectDataAction, dispatch),
    getProjectAction: bindActionCreators(getProjectAction, dispatch),
    syncKypScheduleForOccupantsAction : bindActionCreators(syncKypScheduleForOccupantsAction, dispatch),
    disconnectFromKypAction : bindActionCreators(disconnectFromKypAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
