import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HaveRole } from '../../../utils/RolesAndAbilities'
import { getProjectsAction, searchProjectsAction, addProjectAction, deleteProjectsAction, statusProjectsAction, getProjectModulesAction } from '../../../actions/projectActions';
import { getCustomersAction, getCustomerLicenseAction } from '../../../actions/customerActions';
import { getAccountsAction } from '../../../actions/accountActions';
import HasRole from '../../../components/HasRole/HasRole';
import { getClientsAction } from '../../../actions/clientsActions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Drawer,
  Select,
  message,
  Card,
  Tooltip,
  Switch,
  Steps,
  Typography,
  Divider
} from 'antd';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { Link } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader/PageHeader';
import { bind } from 'lodash';

const Option = Select.Option;

const { Title, Text } = Typography;

class AddProject extends React.Component {

  state = {
    current_step: 0
  };

  form_values = {};

  componentDidMount()
  {
    this.props.getCustomerLicenseAction();

    this.props.getAccountsAction();

    this.props.getClientsAction();

    this.props.getCustomersAction();

    this.props.getProjectModulesAction(null).then(() => {

      this.props.modules.map((module) => {
        this.setState({ ['module_' + module.id]: module.active ? true : false });
      });

      this.setState({ loading: false });
    });
  }

  // step 1
  checkStep1()
  {
    this.props.form.validateFields((err, values) => {

      if (!err) {

        // merge e-mail(s)
        if(Array.isArray(values.mail_occupants_messages_to))
        {
          values.mail_occupants_messages_to = values.mail_occupants_messages_to.join(',');
        }

        //
        this.form_values = values;

        //
        this.setState({ current_step: 1 });
      }

    });
  }

  // step 2
  handleSubmit = e => {

    this.props.form.validateFields((err, values) => {

      if (!err) {

        //
        var modules = Object.keys(values).filter((key) => {

          if(values[key] === true) {

            return key;
          }

          return null;
        });

        //
        this.form_values.ids = modules;

        this.form_values.module_18_name = values.module_18_name;

        // console.log('Submit values:', this.form_values);

        //
        this.props.addProjectAction(this.form_values).then(() => {

          this.props.history.push(`/projects/${this.props.project.id}/general`);

          showSuccess();
        });/**/

      }

    });
  };

  getModulePrice(module_id)
  {
    if(module_id == '22')
    {
      if(this.props.license['extra_project_price'])
      {
        return '€ ' + this.props.license['extra_project_price'] + ',-';
      }
    }
    else
    {
      var module_mapping = [];

      module_mapping['3'] = 'appointment';
      module_mapping['23'] = 'communication';
      module_mapping['14'] = 'support_base';
      module_mapping['19'] = 'form';
      module_mapping['16'] = 'choice';
      module_mapping['15'] = 'delivery';

      if(module_id && module_mapping[module_id] && this.props.license['price_module_' + module_mapping[module_id]])
      {
        return '€ ' + this.props.license['price_module_' + module_mapping[module_id]] + ',-';
      }
    }

    return '€ 0,-';
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

        <PageHeader path={[{'label': 'Projecten', 'link': '/projects'}, {'label': 'Toevoegen'}]} />

        <Row className="mbm">
          <Col span={12}>
            <div>
              {/*<Button type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>*/}
              { this.state.current_step == 0 ?
                <Button type="new" onClick={() => { this.checkStep1(); }}>Volgende</Button>
              :
              <div style={{ display: 'inline-block' }}>
                <Button type="new" onClick={() => { this.handleSubmit(); }}>Opslaan</Button>
              </div>}
              <Link to={`/projects`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Card style={{backgroundColor: '', borderRadius: '5px', marginBottom: 16}} bodyStyle={{padding:0}}>
          <Row>
            <Steps type="navigation" size="small" current={this.state.current_step} onChange={(index) => { this.setState({ current_step: index }); }} style={{display: 'block'}}>
              <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={false} title="Algemene gegevens" />
              <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={true} title="Modules" />
            </Steps>
          </Row>
        </Card>

        <Card>

          <Form className="ant-form--custom" layout={'vertical'} colon={true}>

            { this.state.current_step == 0 ? 
            <div>
              <Row gutter={24}>

                { /* Step 1: PROJECT SETTINGS */ }

                <Col span={10}>
                  <Form.Item hasFeedback label="Projectnaam">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Vul een projectnaam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item hasFeedback label="Interne naam">
                    {getFieldDecorator('internal_name', {
                      // rules: [{ required: true, message: 'Vul een interne naam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item required hasFeedback label="Projectnummer">
                    {getFieldDecorator('project_number', {
                      rules: [{ required: true, message: 'Vul een projectnummer in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                </Col>

                {/*<Col span={4}>
                  <Form.Item hasFeedback label="Kenmerk op de factuur">
                    {getFieldDecorator('invoice_reference', {
                      rules: [{ required: false, message: 'Vul een kenmerk in voor op de factuur' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                </Col>*/}

                {this.props.HaveRole(['super-admin']) ? (
                <Col span={12}>
                  <Form.Item required label="Aangemaakt door" hasFeedback>
                    {getFieldDecorator('user_id', {
                      rules: [{ required: true, message: 'Selecteer een account' } ],
                    })(
                      <Select
                        // loading={this.props.isAccountsFetching}
                        showSearch
                        placeholder="Selecteer een account"
                        optionFilterProp="children"
                        >
                        {this.props.accounts.map((v, k) => (
                          <Select.Option key={v.id} value={v.id}>{v.email}</Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                ) : null}

                <Col span={12}>
                  <Form.Item label={this.props.selectedCustomer.package_id == 3 ? 'Aannemers' : 'Opdrachtgever'} hasFeedback>
                    {getFieldDecorator('client_id', {
                      // initialValue: this.props.project.client_id,
                    })(
                      <Select
                        allowClear
                        showSearch
                        placeholder="Selecteer een opdrachtgever"
                        optionFilterProp="children"
                        >
                        {this.props.clients.map((v, k) => (
                          <Option key={v.id} value={v.id}>{v.name}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item required label={(
                      <span>
                      Meldingen project mailen naar
                      <Tooltip className="mhs" title='Vul hier een of meerdere e-mailadressen in van degenen die een melding mogen ontvangen over activiteiten in het project. Activiteiten: nieuw bericht, enquete ingevuld, afspraak gemaakt of gewijzigd. Meerdere e-mailadressen scheiden met een komma (, ).'>
                        <InfoCircleTwoTone />
                      </Tooltip>
                      </span>
                    )} hasFeedback>
                      {getFieldDecorator('mail_occupants_messages_to', {
                        // initialValue: this.props.project.mail_occupants_messages_to.split(','),
                        rules: [{ required: true, message: 'Vul een e-mailadres in' }],
                      })(
                        <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({mail_occupants_messages_to: value.join(',')}); }}></Select>
                      )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item required label={(
                    <span>
                    Type project
                    <Tooltip className="mhs" title='Als een project op Privé ingesteld staat, krijgen alle adressen een persoonlijke inlog. Wanneer er wordt gekozen voor Openbaar, zal de informatie van het project openbaar zijn. Een project kan alleen openbaar gemaakt worden als er een eigen app gebruikt wordt en of een specifiek (sub)domein is ingesteld. Vraag bij Cobee naar de mogelijkheden hiervoor.'>
                      <InfoCircleTwoTone />
                    </Tooltip>
                    </span>
                  )} hasFeedback>
                        {getFieldDecorator('is_public', {
                            initialValue: '0',
                        })(
                            <Select
                                placeholder="Type project"
                            >
                                  <Select.Option value="0">Privé</Select.Option>
                                  {/*<Select.Option disabled={(this.props.selectedCustomer.project_domain == null) ? true : false} value="1">Openbaar</Select.Option>*/}
                                  <Select.Option disabled={(this.props.selectedCustomer.project_domain == null && !this.props.selectedCustomer.has_app) ? true : false } value="1">Openbaar</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Project verbergen voor bewoners:">
                      {getFieldDecorator('hide_project_for_occupants', {
                        valuePropName: 'checked',
                        // initialValue: this.props.project.hide_project_for_occupants
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      )}
                  </Form.Item>
                </Col>
              </Row>

              <Divider></Divider>

              <Row gutter={24}>
                <Col span={24}>
                  <Title level={4}>Facturatie</Title>
                </Col>
                <Col span={12}>
                  <Form.Item label="Eigen factuur kenmerk" hasFeedback>
                    {getFieldDecorator('invoice_reference', {
                      initialValue: ''
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Project apart factureren">
                        {getFieldDecorator('separate_invoice', {
                          valuePropName: 'checked',
                          initialValue: false
                        })(
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                          />
                        )}
                    </Form.Item>
                  </Col>
              </Row>
            </div>
            :
            <div>
              <Row>

                <Col span={24}>
                  <Row>
                    { !this.state.loading && this.props.modules && this.props.modules.map && this.props.modules.map((module) => {

                      if(module.parent_id == null)
                      {
                        var additional_costs = this.getModulePrice(module.id);

                        return <Col span={12}>
                          <div>
                            <Row>
                              <Col span={24}>
                                <Form.Item>
                                  <Text strong>{module.display_name}</Text>
                                  { module.is_default != '1' ?
                                  (additional_costs ? <Tooltip class="mhs" title={"Voor deze module worden mogelijk extra kosten (" + additional_costs + ") in rekening gebracht."}>
                                      &nbsp;<InfoCircleTwoTone />
                                  </Tooltip> : null)
                                  :
                                  <Tooltip class="mhs" title={"Dit is de basis module voor het project. Hiervoor worden mogelijk extra kosten (" + additional_costs + ") in rekening gebracht. Deze kan niet aan of uitgezet worden. In de basis zitten de volgende modules: adressen, correspondentie en de project media bibliotheek."}>
                                      &nbsp;<InfoCircleTwoTone />
                                  </Tooltip> }
                                  <br />
                                  {getFieldDecorator(String(module.id), {
                                    valuePropName: 'checked',
                                    initialValue: (module.is_default || module.active ? true : false),
                                    onChange: (data) => { this.setState({ ['module_'+module.id]: data });  }
                                  })(
                                    <Switch
                                      disabled={module.is_default == '1' ? true : false}
                                      checkedChildren={<CheckOutlined />}
                                      unCheckedChildren={<CloseOutlined />}
                                      />
                                  )}
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ display: (this.state['module_' + module.id] || module.is_default == '1' ? 'block' : 'none') }}>
                              { this.props.modules.map((sub_module, sub_key) => {

                                if(sub_module.parent_id == module.id)
                                {
                                  return (<Row>
                                    <Col span={24}>
                                      <Form.Item>
                                        <Text>{sub_module.display_name}</Text>
                                        { sub_module.name == 'export' ?
                                        (<Tooltip class="mhs" title="Alleen van toepassing op de modules waar de gebruiker toegang tot heeft en welke een export mogelijkheid bieden.">
                                            &nbsp;<InfoCircleTwoTone />
                                        </Tooltip>) : null }
                                        <br />
                                        {getFieldDecorator(String(sub_module.id), {
                                          valuePropName: 'checked',
                                          initialValue: (sub_module.is_default || sub_module.active ? true : false),
                                          onChange: (data) => { this.setState({ ['module_'+sub_module.id]: data });  }
                                        })(
                                          <Switch
                                            disabled={sub_module.is_default == '1' ? true : false}
                                            checkedChildren={<EyeOutlined />}
                                            unCheckedChildren={<EyeInvisibleOutlined />}
                                            />
                                        )}
                                      </Form.Item>
                                    </Col>
                                    { this.props.modules.map((sub_sub_module, sub_sub_key) => {

                                      if(sub_sub_module.parent_id == sub_module.id)
                                      {
                                          return (<Col span={24}>
                                            <Form.Item>
                                              <Text>{sub_sub_module.display_name}</Text>
                                              <br />
                                              {getFieldDecorator(String(sub_sub_module.id), {
                                                valuePropName: 'checked',
                                                initialValue: (sub_sub_module.is_default || sub_sub_module.active ? true : false),
                                                onChange: (data) => { this.setState({ ['module_'+sub_sub_module.id]: data });  }
                                              })(
                                                <Switch
                                                  // disabled={sub_sub_module.is_default == '1' ? true : false}
                                                  checkedChildren={<EyeOutlined />}
                                                  unCheckedChildren={<EyeInvisibleOutlined />}
                                                  />
                                              )}
                                            </Form.Item>
                                          </Col>)
                                      }
                                    })/**/}
                                  </Row>)
                                }
                              })}
                            </Row>
                          </div>
                        </Col>
                      }

                    }) }
                  </Row>
                  <Row>
                    <Col span={24}>
                      { this.state['module_18'] ?
                      <div>
                          <Divider></Divider>
                          <Row gutter={24}>
                            <Col span={24}>
                              <Title level={4}>Eigen module</Title>
                            </Col>
                            <Col span={12}>
                              <Form.Item label="Naam" hasFeedback>
                                {getFieldDecorator('module_18_name', {
                                  initialValue: String(this.props.project.module_18_name ? this.props.project.module_18_name : 'Eigen module'),
                                })(
                                  <Input maxLength={24} />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                      </div> : null }
                    </Col>
                  </Row>
                </Col>

              </Row>
            </div>}

          </Form>

        </Card>

      </div>
    );
  }
}

const AddProjectForm = Form.create({ name: 'projects' })(AddProject);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isCustomersFetching : state.customer.isFetching,
    isAccountsFetching : state.account.isFetching,
    isError : state.project.isError,
    project: state.project.project,
    customers: state.customer.customers,
    accounts: state.account.accounts,
    clients: state.clients.clients,
    selectedCustomer: state.auth.selectedCustomer,
    modules: state.project.modules,
    license: state.customer.license,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectsAction : bindActionCreators(getProjectsAction, dispatch),
    addProjectAction : bindActionCreators(addProjectAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    getAccountsAction : bindActionCreators(getAccountsAction, dispatch),
    deleteProjectsAction : bindActionCreators(deleteProjectsAction, dispatch),
    statusProjectsAction : bindActionCreators(statusProjectsAction, dispatch),
    searchProjectsAction : bindActionCreators(searchProjectsAction, dispatch),
    getClientsAction : bindActionCreators(getClientsAction, dispatch),
    getProjectModulesAction : bindActionCreators(getProjectModulesAction, dispatch),
    getCustomerLicenseAction : bindActionCreators(getCustomerLicenseAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectForm);
