import _ from 'underscore';

export default (state = {
    isError: false,
    isFetching: false,
    dayschedule: [],
    schedule: {},
    group: {},
    address_ids: [],
    gantt_data: [],
    gantt_calendar: [],
    addresses: [],
    blockeddates: [],
    kyp_projects: [],
    kyp_addresses: [],
    kyp_items: [],
    kyp_status: [],
}, action) => {

    switch (action.type) {
    case 'SCHEDULE_ACTION_FETCH':
    return {
        ...state,
        // schedule: {},
        isFetching: true,
        isError: false,
    }
    case 'SCHEDULE_BLOCKEDDATES_ACTION_FETCH':
    return {
        ...state,
        blockeddates: [],
        isFetching: true,
        isError: false,
    }
    case 'SCHEDULE_DAYSCHEDULE_ACTION_FETCH':
    return {
        ...state,
        dayschedule: [],
        isFetching: true,
        isError: false,
    }
    case 'SCHEDULE_ACTION_GANTT_UPDATE':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'SCHEDULE_ACTION_SUCCESS':
    return {
        ...state,
        schedule: action.payload.schedule,
        addresses: action.payload.addresses,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_GROUPADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        address_ids: action.payload.address_ids,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_GROUPADDRESSES_UPDATE_SUCCESS':
    return {
        ...state,
        address_ids: _.pluck(action.payload.addresses, 'address_id'),
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_GROUP_ACTION_SUCCESS':
    return {
        ...state,
        group: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_BLOCKEDDATES_ACTION_SUCCESS':
    return {
        ...state,
        blockeddates: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_DAYSCHEDULE_ACTION_SUCCESS':
    return {
        ...state,
        dayschedule: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_BLOCKEDDATES_ADD_SUCCESS':
    return {
        ...state,
        blockeddates: action.payload.blocked_dates,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_GROUPDAYS_ACTION_SUCCESS':
    return {
        ...state,
        days: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_BLOCKEDDATES_REMOVE_SUCCESS':
    return {
        ...state,
        blockeddates: state.blockeddates.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_GROUP_ADD_SUCCESS':
    return {
        ...state,
        group: action.payload.group,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_DAYSCHEDULE_REMOVE_SUCCESS':
    return {
        ...state,
        dayschedule: state.dayschedule.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_GANTT_SUCCESS':
    return {
        ...state,
        gantt_data: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ACTION_GANTT_SUCCESS':
    return {
        ...state,
        gantt_calendar: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_GANTT_UPDATE_SUCCESS':
    return {
        ...state,
        gantt_data: action.payload.gantt_data,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_SETTINGS_UPDATE_SUCCESS':
    return {
        ...state,
        schedule: action.payload.schedule,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_DUPLICATE_SUCCESS':
    return {
        ...state,
        dayschedule: [...state.dayschedule, action.payload.group],
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_RESET':
    return {
        ...state,
        dayschedule: [],
        // schedule: {},
        group: {},
        address_ids: [],
        gantt_data: [],
        gantt_calendar: [],
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
        // schedule: {},
        addresses: []
    }
    case 'SCHEDULE_ACTION_GANTT_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'SCHEDULE_ACTION_SETTINGS_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'SCHEDULE_ACTION_KYP_PROJECTS_SUCCESS':
    return {
        ...state,
        kyp_projects: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_KYP_PROJECT_ADDRESSES_SUCCESS':
    return {
        ...state,
        kyp_addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_KYP_PROJECT_ITEMS_SUCCESS':
    return {
        ...state,
        kyp_items: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_KYP_PROJECT_STATUS_SUCCESS':
    return {
        ...state,
        kyp_status: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_ACTION_KYP_PROJECT_ACCESS_TOKEN_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    default:
        return state
    }
}
