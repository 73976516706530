import React, { Component } from 'react';
// import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';
import { Row, Col, Card, Select, Button, Checkbox, Input, Tooltip } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../../utils/Notifications';
import ScheduleKypSteps from '../Steps/ScheduleKypSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { updateScheduleSettingsAction, getScheduleKypItemsAction, refreshKypProjectDataAction, getScheduleAction } from '../../../../../actions/scheduleActions';
import { getInformationAction } from '../../../../../actions/informationActions';
import { getProjectAction } from '../../../../../actions/projectActions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { InfoCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';

class ScheduleKypInformationItems extends Component
{
  state = {
      cobee_kyp_addresses: [],
      cobee_kyp_items: [],
      loading: true
  };

  componentDidMount()
  {
    this.setState({ loading: true });

    this.props.getProjectAction(this.props.match.params.id).then(() => {

      this.props.getScheduleKypItemsAction(this.props.match.params.id, this.props.project.kyp_project_id).then(() => {

          this.setState({ cobee_kyp_items: this.props.kyp_items, loading: false });
      });

      this.props.getScheduleAction(this.props.match.params.id).then(() => {});

      this.props.getInformationAction(this.props.match.params.id);
    });
  }
  
  refreshKypProjectData()
  {
    this.props.refreshKypProjectDataAction(this.props.project.id).then((status) => {

      if(status)
      {
        showSuccess();

        this.load();
      }
      else
      {
        showError('Wacht even met het opnieuw proberen.');
      }
    });
  }

  saveSettings(e) {

      var values = {};

      values.cobee_kyp_items = JSON.stringify(this.state.cobee_kyp_items);

      this.props.updateScheduleSettingsAction(this.props.match.params.id, values).then(() => {

        showSuccess('Succesvol opgeslagen');

        this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/kyp-status`);
      });
  }

  toggleHighlightKypItem(kyp_item, checked)
  {
    // console.log('toggleShowKypItem', kyp_item, checked);

    var cobee_kyp_items = this.state.cobee_kyp_items;

    cobee_kyp_items.map((cobee_kyp_item) => {
        if(cobee_kyp_item.name == kyp_item.name)
        {
            cobee_kyp_item.highlight = checked;

            return;
        }
    });

    this.setState({ loading: true }, () => {

      this.setState({ 
        cobee_kyp_items: cobee_kyp_items,
        loading: false
      });
    });
  }

  toggleShowKypItem(kyp_item, checked)
  {
    // console.log('toggleShowKypItem', kyp_item, checked);

    var cobee_kyp_items = this.state.cobee_kyp_items;

    cobee_kyp_items.map((cobee_kyp_item) => {
        if(cobee_kyp_item.name == kyp_item.name)
        {
            cobee_kyp_item.show = checked;

            return;
        }
    });

    this.setState({ loading: true }, () => {

      this.setState({ 
        cobee_kyp_items: cobee_kyp_items,
        loading: false
      });
    });
  }

  render()
  {
    if(this.state.loading){ return null; }

    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'KYP taak',
        dataIndex: 'name',
        render: (text, kyp_item) => (
          <span>{text}</span>
        )
      },
      {
        title: () => { return <span>
          Tonen op planning
          <Tooltip className="mhs" overlayStyle={{ whiteSpace: 'pre-line' }} title={"Met deze optie kan je aangeven welke KYP taken op de bewonersplanning in Cobee getoond worden."}>
            <InfoCircleTwoTone />
          </Tooltip>
        </span>},
        dataIndex: 'show',
        width: 180,
        render: (text, kyp_item) => (
          <span>
            {getFieldDecorator('show['+ kyp_item.name +']', {
              valuePropName: 'checked',
              initialValue: kyp_item.show ? true : false,
              onChange: (e) => { this.toggleShowKypItem(kyp_item, e.target.checked); },
            })(
              <Checkbox
                disabled={kyp_item.status ? true : false}
                // checked={kyp_item.show ? true : false}
                />
            )}
          </span>
        )
      },
      {
        title: () => { return <span>
          Extra uitlichten
          <Tooltip className="mhs" overlayStyle={{ whiteSpace: 'pre-line' }} title={"Met deze optie kan je aangeven welke KYP taken op de bewonersplanning extra uitgelicht worden op de kalender in Cobee."}>
            <InfoCircleTwoTone />
          </Tooltip>
        </span>},
        dataIndex: 'highlight',
        width: 180,
        render: (text, kyp_item) => (
          <span>
            {getFieldDecorator('highlight['+ kyp_item.name +']', {
              valuePropName: 'checked',
              initialValue: kyp_item.highlight ? true : false,
              onChange: (e) => { this.toggleHighlightKypItem(kyp_item, e.target.checked); },
            })(
              <Checkbox
                disabled={kyp_item.status ? true : false}
                // checked={kyp_item.show ? true : false}
                />
            )}
          </span>
        )
      },
      {
        title: 'Cobee informatie',
        dataIndex: 'information_item_id',
        render: (text, kyp_item) => (
          <div>
            <Row>
              <Col>
                  {getFieldDecorator('type_of_information['+ kyp_item.name +']', {
                    // initialValue: 'cobee',
                    initialValue: (kyp_item.type_of_information ? kyp_item.type_of_information : 'cobee'),
                  })(
                    <Select
                      style={{ marginRight: 6, width: 160 }}
                      disabled={(/*this.props.form.getFieldValue('show['+ kyp_item.name +']') &&*/ !kyp_item.status && kyp_item.show) ? false : true}
                      // defaultValue={(kyp_item.type_of_information ? kyp_item.type_of_information : 'cobee')}
                      onChange={(value) => {
                          var cobee_kyp_items = this.state.cobee_kyp_items;
  
                          cobee_kyp_items.map((cobee_kyp_item) => {
                              if(cobee_kyp_item.name == kyp_item.name)
                              {
                                  cobee_kyp_item.type_of_information = value;
                              }
                          });
  
                          this.setState({ cobee_kyp_items: cobee_kyp_items });
                      }}
                      >
                      <Select.Option value={'cobee'}>Informatie tekst</Select.Option>
                      <Select.Option value={'own'}>Eigen label</Select.Option>
                    </Select>
                  )}
              </Col>
              <Col>
                { this.props.form.getFieldValue('type_of_information['+ kyp_item.name +']') != 'own' ? <Select
                    name={'cobee_information['+ kyp_item.name +']'}
                    defaultValue={(kyp_item.information_item_id ? ''+kyp_item.information_item_id : null)}
                    onChange={(value) => {
                        var cobee_kyp_items = this.state.cobee_kyp_items;

                        cobee_kyp_items.map((cobee_kyp_item) => {
                            if(cobee_kyp_item.name == kyp_item.name)
                            {
                                cobee_kyp_item.information_item_id = ''+value;
                            }
                        });

                        this.setState({ cobee_kyp_items: cobee_kyp_items });
                    }}
                    style={{ width: '100%' }}
                    placeholder={'Kies een informatie tekst'}
                    disabled={(/*this.props.form.getFieldValue('show['+ kyp_item.name +']') &&*/ !kyp_item.status && kyp_item.show) ? false : true}
                    >
                    { this.props.information_items && this.props.information_items.map && this.props.information_items.map((information_item) => {
                        return <Select.Option value={''+information_item.id}>{information_item.title}</Select.Option>
                    }) }
                </Select>
                :
                <Input 
                  name={'custom_value['+ kyp_item.name +']'}
                  placeholder={'Vul hier je eigen tekst in'}
                  value={kyp_item.custom_value ? ''+kyp_item.custom_value : null}
                  onChange={(e) => {
                    var cobee_kyp_items = this.state.cobee_kyp_items;

                    cobee_kyp_items.map((cobee_kyp_item) => {
                        if(cobee_kyp_item.name == kyp_item.name)
                        {
                            cobee_kyp_item.custom_value = e.target.value;
                        }
                    });

                    this.setState({ cobee_kyp_items: cobee_kyp_items });
                  }}
                  disabled={this.props.form.getFieldValue('show['+ kyp_item.name +']') ? false : true}
                  style={{ minWidth: 300, width: 300 }}
                  />}
              </Col>
            </Row>
          </div>
        ),
      }
    ];

    return (
      <Form>
        <div>
          <Row className="mbm">
            <Col span={24}>
              <div>
                <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={(e) => { this.saveSettings(e); }}>Opslaan en verder</Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
              <ScheduleKypSteps current={2} {...this.props} />
            </Card>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Card>

                  { this.props.project.kyp_project_id ? <Row gutter={24} style={{ marginBottom: 24 }}>
                    <Col span={24}>
                      <Button style={{ marginRight: 0 }} onClick={() => { this.refreshKypProjectData(); }}>Planning ophalen</Button>
                      <Tooltip className="mhs" overlayStyle={{ whiteSpace: 'pre-line' }} title={"Met deze knop kan je de meest actuele versie van de planning in KYP ophalen en bijwerken in Cobee. De planning kan maximaal één maal per minuut worden bijgewerkt."}>
                        <InfoCircleTwoTone />
                      </Tooltip>
                      (laatst opgehaald: {this.props.schedule.kyp_schedule_updated_at ? moment(this.props.schedule.kyp_schedule_updated_at).locale('NL').format('DD-MM-YYYY HH:mm:ss') : 'nooit'})
                    </Col>
                  </Row> : null }

                  <CustomTable
                    rowKey='id'
                    size="middle"
                    columns={columns}
                    dataSource={this.state.cobee_kyp_items}
                    loading={this.props.isFetching || this.state.refresh}
                    pagination={false}
                    />

              </Card>
            </Col>
          </Row>
        </div>
      </Form>
    );
  }
}

const ScheduleKypInformationItemsForm = Form.create({ name: 'kyp_information_items_form' })(ScheduleKypInformationItems);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    project: state.project.project,
    information_items: state.information.information,
    kyp_items: state.schedule.kyp_items,
    schedule: state.schedule.schedule,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleSettingsAction : bindActionCreators(updateScheduleSettingsAction, dispatch),
    getInformationAction : bindActionCreators(getInformationAction, dispatch),
    getScheduleKypItemsAction : bindActionCreators(getScheduleKypItemsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getProjectAction: bindActionCreators(getProjectAction, dispatch),
    refreshKypProjectDataAction: bindActionCreators(refreshKypProjectDataAction, dispatch),
    getScheduleAction: bindActionCreators(getScheduleAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleKypInformationItemsForm);
