import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { Modal } from "antd";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLoginUserAction } from './actions/authAction';
// import '@ant-design/compatible/assets/index.css';
// import 'antd/dist/antd.css';
// import 'ant-design-pro/dist/ant-design-pro.css';
import { API_URL, COBEE_VERSION } from './constants/settings.js';
import './App.css';

// DIRECT ACCESS SCREENS
import ChangePointStatus from './screens/Standalone/ChangePointStatus';
import KypOauth from './screens/Standalone/KypOauth';

// LAYOUT & SCREENS
import AppLayout from './components/AppLayout/AppLayout';

import Status403 from './screens/Status/Status403';
import Status500 from './screens/Status/Status500';
import ErrorPage from './screens/Status/Error';

// Transactions
import AdministrationTransactions from './screens/Administration/CustomerTransactions';
import ChoiceTransactions from './screens/Administration/ChoiceTransactions';
import PriceCalculator from './screens/Administration/PriceCalculator';

// Accounts
import Accounts from './screens/Accounts/Accounts';
import AccountsDetail from './screens/Accounts/Detail/AccountsDetail';
import AccountsProfile from './screens/Accounts/Detail/AccountsProfile';
import AccountRoles from './screens/Accounts/AccountRoles';
import AccountRolesDetail from './screens/Accounts/AccountRole';
import DeliveryUsers from './screens/Accounts/DeliveryUsers';
import DeliveryUserDetail from './screens/Accounts/DeliveryUser';
import AppointmentUsers from './screens/Accounts/AppointmentUsers';
import AppointmentUserDetail from './screens/Accounts/AppointmentUser';


// Library
import Library from './screens/Library/Library';
import LibraryCategory from './screens/Library/LibraryCategories';
import LibraryDetail from './screens/Library/Detail/LibraryDetail';
import LibraryCategoryDetail from './screens/Library/Detail/LibraryCategoryDetail';
import GlobalPartners from './screens/Library/Partners';
import GlobalPartnerDetail from './screens/Library/Detail/PartnerDetail'; // <- ant v.5 error
import Clients from './screens/Library/Clients';
import ClientDetail from './screens/Library/Detail/ClientDetail';
import CorporateIdentity from './screens/Library/CorporateIdentity';
import CorporateIdentityDetail from './screens/Library/Detail/CorporateIdentityDetail';
import InformationTexts from './screens/Library/InformationTexts';
import InformationTextsCategories from './screens/Library/InformationTextsCategories';
import InformationTextDetail from './screens/Library/Detail/InformationTextDetail';
import InformationTextCategoryDetail from './screens/Library/Detail/InformationTextCategory';
import LibraryIntake from './screens/Library/Intake';
import LibraryDelivery from './screens/Library/Delivery';
import LibraryDeliveryDetail from './screens/Library/Detail/DeliveryDetail';
import LibraryDeliverySettings from './screens/Library/Detail/DeliverySettings';
import LibraryDeliveryProjects from './screens/Library/Detail/DeliveryProjects';
import IntakeDetail from './screens/Library/Detail/IntakeDetail';
import LibrarySupportBase from './screens/Library/SupportBase';
import SupportBaseDetail from './screens/Library/Detail/SupportBaseDetail';
import LibraryEnvelopes from './screens/Library/Envelopes';
import EnvelopeDetail from './screens/Library/Detail/EnvelopeDetail';
import LibrarySurveys from './screens/Library/Surveys';
import LibrarySurveyDetail from './screens/Library/Detail/SurveyDetail';
import LibraryChoice from './screens/Library/Choice';
import ChoiceDetail from './screens/Library/Detail/ChoiceDetail';
import ContactsLibrary from './screens/Library/Contacts';
import ContactsLibraryDetail from './screens/Library/Detail/LibraryContactsDetail';
import DeliveryUsersLibrary from './screens/Library/DeliveryUsers';
import DeliveryUserLibraryDetail from './screens/Library/Detail/DeliveryUserDetail';
import LibraryForms from './screens/Library/Forms';
import LibraryFormDetail from './screens/Library/Detail/FormDetail';
import LibraryFormSettings from './screens/Library/Detail/FormSettings';
import LibraryFormProjects from './screens/Library/Detail/FormProjects';

// MediaLibrary
import Media from './screens/Media/Media';
import MediaDetail from './screens/Media/Detail/MediaDetail';
import MediaCategories from './screens/Media/MediaCategories';
import MediaCategory from './screens/Media/Detail/MediaCategory';

// Customer screens
import Customer from './screens/Customers/Customers';

import CustomerGeneral from './screens/Customers/Detail/CustomerGeneral';
import CustomerPersonalize from './screens/Customers/Detail/CustomerPersonalize';
import CustomerConnections from './screens/Customers/Detail/CustomerConnections';
import CustomerModules from './screens/Customers/Detail/CustomerModules';
import CustomerLicense from './screens/Customers/Detail/CustomerLicense';
import CustomerBilling from './screens/Customers/Detail/CustomerBilling';
import CustomerTransactions from './screens/Customers/Detail/CustomerTransactions';

// Login
import Refresh from './screens/Login/Refresh';
import Update from './screens/Login/Update';
import Login from './screens/Login/Login';
import Reset from './screens/Login/Reset/Reset';
import Forget from './screens/Login/Forget/Forget';

// Profile
import Profile from './screens/Profile/Profile';
import Settings from './screens/Profile/Settings/Settings';
import SettingsClient from './screens/Profile/Settings/SettingsClient/SettingsClient';
import Dashboard from './screens/Dashboard/Dashboard';

// Modules
// - Chat
import Chat from './screens/Projects/Modules/Chat/Chat';
import ChatSettings from './screens/Projects/Modules/Chat/ChatSettings';

// - Workorder
import Workorder from './screens/Projects/Modules/Workorder/Workorder';
import WorkorderSettings from './screens/Projects/Modules/Workorder/WorkorderSettings';

// - News
import News from './screens/Projects/Modules/News/News';
import NewsDetail from './screens/Projects/Modules/News/Detail/NewsDetail';
import NewsAddresses from './screens/Projects/Modules/News/Detail/NewsAddresses';

// - Complaints
import Complaints from './screens/Projects/Modules/Complaints/Complaints';
import ComplaintsDetail from './screens/Projects/Modules/Complaints/Detail/ComplaintsDetail';
import ComplaintsAddresses from './screens/Projects/Modules/Complaints/Detail/ComplaintsAddresses';

// - Information
import Information from './screens/Projects/Modules/Information/Information';
import InformationDetail from './screens/Projects/Modules/Information/Detail/InformationDetail';
import InformationAddresses from './screens/Projects/Modules/Information/Detail/InformationAddresses';
import InformationMenu from './screens/Projects/Modules/Information/InformationMenu';
import InformationMenuDetail from './screens/Projects/Modules/Information/Detail/InformationMenuDetail';

// - Survey
import Survey from './screens/Projects/Modules/Survey/Survey';
import SurveyDetail from './screens/Projects/Modules/Survey/Detail/SurveyDetail';
import SurveyQuestions from './screens/Projects/Modules/Survey/Detail/SurveyQuestions';
import SurveyQuestion from './screens/Projects/Modules/Survey/Detail/SurveyQuestion';
import SurveyQuestionsV2 from './screens/Projects/Modules/Survey/Detail/SurveyQuestionsV2';
import SurveyAddresses from './screens/Projects/Modules/Survey/Detail/SurveyAddresses';
import SurveyResults from './screens/Projects/Modules/Survey/Detail/SurveyResults';
import SurveyResultsV2 from './screens/Projects/Modules/Survey/Detail/SurveyResultsV2';

// - Documents
import Documents from './screens/Projects/Modules/Documents/Documents';
import DocumentDetail from './screens/Projects/Modules/Documents/Detail/DocumentDetail';
import DocumentAddresses from './screens/Projects/Modules/Documents/Detail/DocumentAddresses';

// - Contacts
import Contacts from './screens/Projects/Modules/Contacts/Contacts';
import ContactDetail from './screens/Projects/Modules/Contacts/Detail/ContactDetail';

// - Partners
import Partners from './screens/Projects/Modules/Partners/Partners';
import PartnerDetail from './screens/Projects/Modules/Partners/Detail/PartnerDetail';

// - Schedule
import Schedule from './screens/Projects/Modules/Schedule/Schedule';
import ScheduleSettings from './screens/Projects/Modules/Schedule/Detail/ScheduleSettings';
import ScheduleKypAddresses from './screens/Projects/Modules/Schedule/Detail/ScheduleKypAddresses';
import ScheduleKypInformationItems from './screens/Projects/Modules/Schedule/Detail/ScheduleKypInformationItems';
import ScheduleKypStatus from './screens/Projects/Modules/Schedule/Detail/ScheduleKypStatus';
import ScheduleBlockedDates from './screens/Projects/Modules/Schedule/Detail/ScheduleBlockedDates';
import ScheduleDaySchedule from './screens/Projects/Modules/Schedule/Detail/ScheduleDaySchedule';
import ScheduleDayScheduleDetail from './screens/Projects/Modules/Schedule/Detail/ScheduleDayScheduleDetail';
import ScheduleCalendar from './screens/Projects/Modules/Schedule/Detail/ScheduleCalendar';
import ScheduleExport from './screens/Projects/Modules/Schedule/Detail/ScheduleExport';

// - Appointments
import Appointments from './screens/Projects/Modules/Appointments/Appointments';
import AppointmentDetail from './screens/Projects/Modules/Appointments/Detail/AppointmentDetail';
import AppointmentAddresses from './screens/Projects/Modules/Appointments/Detail/AppointmentAddresses';
import AppointmentAvailability from './screens/Projects/Modules/Appointments/Detail/AppointmentAvailability';
import AppointmentClusters from './screens/Projects/Modules/Appointments/Detail/AppointmentClusters';
import AppointmentData from './screens/Projects/Modules/Appointments/Detail/AppointmentData';
import AppointmentOverview from './screens/Projects/Modules/Appointments/Detail/AppointmentOverview';
// import AppointmentAgenda from './screens/Projects/Modules/Appointments/Detail/AppointmentAgenda';
import AppointmentsUsers from './screens/Projects/Modules/Appointments/AppointmentsUsers';
import AppointmentsUser from './screens/Projects/Modules/Appointments/AppointmentsUser';
import AppointmentsStatus from './screens/Projects/Modules/Appointments/AppointmentsStatus';
import AppointmentsCalendar from './screens/Projects/Modules/Appointments/AppointmentsCalendar';

// Projects screens
import Projects from './screens/Projects/Projects';
import ProjectDetail from './screens/Projects/Detail/AddProject';

import ProjectsDashboard from './screens/Projects/Dashboard/ProjectsDashboard';
import ProjectsAddresses from './screens/Projects/Addresses/ProjectsAddresses';
import ProjectsAddressesImport from './screens/Projects/Addresses/ProjectsAddressesImport';
import ProjectsAddressesZipcodeImport from './screens/Projects/Addresses/ProjectsAddressesZipcodeImport';
import ProjectsAddressesMapImport from './screens/Projects/Addresses/ProjectsAddressesMapImport';
import ProjectsAddressesGeneral from './screens/Projects/Addresses/Detail/ProjectsAddressesGeneral';
import ProjectsAddressesContactpersons from './screens/Projects/Addresses/Detail/ProjectsAddressesContactpersons';
import ProjectsAddressesContactpersonsDetail from './screens/Projects/Addresses/Detail/ProjectsAddressesContactpersonsDetail';
import ProjectsAddressesDossier from './screens/Projects/Addresses/Detail/ProjectsAddressesDossier';
import ProjectsAddressesDossierNote from './screens/Projects/Addresses/Detail/ProjectsAddressesDossierNote';
import ProjectAddressFieldValues from './screens/Projects/Addresses/Detail/ProjectAddressFieldValues';
import ProjectAddressFields from './screens/Projects/Addresses/ProjectAddressFields';
import ProjectAddressField from './screens/Projects/Addresses/ProjectAddressField';
import AddressBagData from './screens/Projects/Addresses/Detail/AddressBagData';

import ProjectsSettings from './screens/Projects/Settings/ProjectsSettingsGeneral';
import ProjectTransactions from './screens/Projects/Settings/ProjectTransactions';
import ProjectsPersonalize from './screens/Projects/Settings/ProjectsPersonalize';
import ProjectsModules from './screens/Projects/Settings/ProjectModules';
import ProjectsUsers from './screens/Projects/Settings/ProjectUsers';
import ProjectUser from './screens/Projects/Settings/ProjectUser';

import ProjectMedia from './screens/Projects/Media/ProjectMedia';
import ProjectMediaCategories from './screens/Projects/Media/ProjectMediaCategories';
import ProjectMediaDetail from './screens/Projects/Media/Detail/ProjectMediaDetail';
import ProjectMediaCategory from './screens/Projects/Media/Detail/ProjectMediaCategory';

import ProjectsPushNotification from './screens/Projects/Correspondence/ProjectsPushNotification';
import AddPublicPush from './screens/Projects/Correspondence/Detail/AddPublicPush';
import SendPublicPush from './screens/Projects/Correspondence/Detail/SendPublicPush';
import StatusPublicPush from './screens/Projects/Correspondence/Detail/StatusPublicPush';

import ProjectsCorrespondence from './screens/Projects/Correspondence/ProjectsCorrespondence';
import ProjectsCorrespondenceSetup from './screens/Projects/Correspondence/Detail/ProjectsCorrespondenceSetup';
import ProjectsCorrespondenceCompose from './screens/Projects/Correspondence/Detail/ProjectsCorrespondenceCompose';
import ProjectsCorrespondenceSend from './screens/Projects/Correspondence/Detail/ProjectsCorrespondenceSend';
import ProjectsCorrespondenceStatus from './screens/Projects/Correspondence/Detail/ProjectsCorrespondenceStatus';

import ComposeMail from './screens/Projects/Correspondence/Detail/Compose/ComposeMail';
import ComposeSMS from './screens/Projects/Correspondence/Detail/Compose/ComposeSMS';
import ComposePush from './screens/Projects/Correspondence/Detail/Compose/ComposePush';
import ComposeEmail from './screens/Projects/Correspondence/Detail/Compose/ComposeEmail';

import ProjectsGroups from './screens/Projects/Groups/ProjectsGroups';
import ProjectsGroupsDetail from './screens/Projects/Groups/Detail/ProjectsGroupDetail';

// Intake (module: 13)
import ProjectIntake from './screens/Projects/Intake/ProjectIntake';
import ProjectIntakeSetup from './screens/Projects/Intake/Detail/ProjectIntakeSetup';
import ProjectIntakeDetail from './screens/Projects/Intake/Detail/ProjectIntakeDetail';
import ProjectIntakeAddresses from './screens/Projects/Intake/Detail/ProjectIntakeAddresses';
import ProjectIntakeResults from './screens/Projects/Intake/Detail/ProjectIntakeResults';
import ProjectIntakeResult from './screens/Projects/Intake/Detail/ProjectIntakeResult';

// Support Base (module: 14)
import ProjectSupportBase from './screens/Projects/SupportBase/ProjectSupportBase';
import SupportBaseFormAddresses from './screens/Projects/SupportBase/Form/SupportBaseFormAddresses';
import SupportBaseFormCompose from './screens/Projects/SupportBase/Form/SupportBaseFormCompose';
import SupportBaseFormSend from './screens/Projects/SupportBase/Form/SupportBaseFormSend';

import SupportBaseCorrespondenceForm from './screens/Projects/SupportBase/Form/Correspondence/SupportBaseCorrespondenceForm';
import SupportBaseCorrespondenceLetter from './screens/Projects/SupportBase/Form/Correspondence/SupportBaseCorrespondenceLetter';
import SupportBaseCorrespondenceEmail from './screens/Projects/SupportBase/Form/Correspondence/SupportBaseCorrespondenceEmail';
import SupportBaseCorrespondenceAttachments from './screens/Projects/SupportBase/Form/Correspondence/SupportBaseCorrespondenceAttachments';
import SupportBaseCorrespondenceCheck from './screens/Projects/SupportBase/Form/Correspondence/SupportBaseCorrespondenceCheck';
import SupportBaseCorrespondenceStatus from './screens/Projects/SupportBase/Form/Correspondence/SupportBaseCorrespondenceStatus';

import ProjectSupportBaseSettings from './screens/Projects/SupportBase/ProjectSupportBaseSettings';

import ProjectSupportBaseReminders from './screens/Projects/SupportBase/ProjectSupportBaseReminders';
import SupportBaseReminder from './screens/Projects/SupportBase/Reminder/SupportBaseReminder';
import SupportBaseReminderLetter from './screens/Projects/SupportBase/Reminder/SupportBaseReminderLetter';
import SupportBaseReminderEmail from './screens/Projects/SupportBase/Reminder/SupportBaseReminderEmail';
import SupportBaseReminderCheck from './screens/Projects/SupportBase/Reminder/SupportBaseReminderCheck';

import ProjectSupportBaseStatus from './screens/Projects/SupportBase/ProjectSupportBaseStatus';

import SupportBaseResendAddresses from './screens/Projects/SupportBase/Status/Resend/SupportBaseResendAddresses';
import SupportBaseResendCompose from './screens/Projects/SupportBase/Status/Resend/SupportBaseResendCompose';
import SupportBaseFormResend from './screens/Projects/SupportBase/Status/Resend/SupportBaseFormResend';
// import SupportBaseResendCorrespondenceForm from './screens/Projects/SupportBase/Status/Resend/Correspondence/SupportBaseCorrespondenceForm';
import SupportBaseResendCorrespondenceLetter from './screens/Projects/SupportBase/Status/Resend/Correspondence/SupportBaseResendCorrespondenceLetter';
import SupportBaseResendCorrespondenceEmail from './screens/Projects/SupportBase/Status/Resend/Correspondence/SupportBaseResendCorrespondenceEmail';
// import SupportBaseResendCorrespondenceAttachments from './screens/Projects/SupportBase/Status/Resend/Correspondence/SupportBaseCorrespondenceAttachments';
import SupportBaseResendCorrespondenceCheck from './screens/Projects/SupportBase/Status/Resend/Correspondence/SupportBaseResendCorrespondenceCheck';

import SupportBaseReminderAddresses from './screens/Projects/SupportBase/Status/Reminder/SupportBaseReminderAddresses';
import SupportBaseReminderCompose from './screens/Projects/SupportBase/Status/Reminder/SupportBaseReminderCompose';
import SupportBaseFormReminder from './screens/Projects/SupportBase/Status/Reminder/SupportBaseFormReminder';
// import SupportBaseReminderCorrespondenceForm from './screens/Projects/SupportBase/Status/Reminder/Correspondence/SupportBaseCorrespondenceForm';
import SupportBaseReminderCorrespondenceLetter from './screens/Projects/SupportBase/Status/Reminder/Correspondence/SupportBaseReminderCorrespondenceLetter';
import SupportBaseReminderCorrespondenceEmail from './screens/Projects/SupportBase/Status/Reminder/Correspondence/SupportBaseReminderCorrespondenceEmail';
// import SupportBaseReminderCorrespondenceAttachments from './screens/Projects/SupportBase/Status/Reminder/Correspondence/SupportBaseCorrespondenceAttachments';
import SupportBaseReminderCorrespondenceCheck from './screens/Projects/SupportBase/Status/Reminder/Correspondence/SupportBaseReminderCorrespondenceCheck';

// Deliver (module: 15)
import DeliverPoints from './screens/Projects/Deliver/DeliverPoints';
import DeliverPointsNotifications from './screens/Projects/Deliver/DeliverPointsNotifications';
import DeliverPoint from './screens/Projects/Deliver/DeliverPoint';
import DeliverSettings from './screens/Projects/Deliver/DeliverSettings';
import DeliverDrawings from './screens/Projects/Deliver/DeliverDrawings';
import Deliveries from './screens/Projects/Deliver/Deliveries';
import DeliveryDetails from './screens/Projects/Deliver/Delivery/DeliveryDetails';
import DeliveryAddresses from './screens/Projects/Deliver/Delivery/DeliveryAddresses';
//import DeliveryAddresses from './screens/Projects/Deliver/DeliveryAddresses';
import DeliverUsers from './screens/Projects/Deliver/DeliverUsers';
import DeliverInformedUsers from './screens/Projects/Deliver/DeliverInformedUsers';
import DeliveryForms from './screens/Projects/Deliver/DeliveryForms';
import DeliveryFormSettings from './screens/Projects/Deliver/Form/DeliveryFormSettings';
import DeliveryFormDeliveries from './screens/Projects/Deliver/Form/DeliveryFormDeliveries';
import DeliveryForm from './screens/Projects/Deliver/Form/DeliveryForm';
import DeliveryFormPoints from './screens/Projects/Deliver/Form/DeliveryFormPoints';
import DeliveryFormStatus from './screens/Projects/Deliver/Form/DeliveryFormStatus';
import DeliverStatus from './screens/Projects/Deliver/DeliverStatus';

// Choice (module: 16)
import ChoiceSettings from './screens/Projects/Choice/ChoiceSettings';
import ChoiceForms from './screens/Projects/Choice/ChoiceForms';
import ChoiceFormAddresses from './screens/Projects/Choice/Form/ChoiceFormAddresses';
import ChoiceFormSend from './screens/Projects/Choice/Form/ChoiceFormSend';
import ChoiceFormStatus from './screens/Projects/Choice/Form/ChoiceFormStatus';
import ChoiceFormCompose from './screens/Projects/Choice/Form/ChoiceFormCompose';
import ChoiceFormsStatus from './screens/Projects/Choice/ChoiceFormsStatus';
import ChoiceFormSettings from './screens/Projects/Choice/Form/ChoiceFormSettings';

// Fuse Box List
//import FuseBoxList from './screens/Projects/FuseBoxList/FuseBoxList';

// Export
import Export from './screens/Projects/Modules/Export/Export';
import DemoCombinedExport from './screens/Projects/Modules/Export/DemoCombinedExport';

// Form (module: 19)
// import Forms from './screens/Projects/Modules/Form/Forms';
// import FormSetup from './screens/Projects/Modules/Form/FormSetup';
// import FormAddresses from './screens/Projects/Modules/Form/FormAddresses';
// import FormResults from './screens/Projects/Modules/Form/FormResults';

// Form (module: 19)
import Forms from './screens/Projects/Form/Forms';
import FormSettings from './screens/Projects/Form/Form/FormSettings';
import FormCompose from './screens/Projects/Form/Form/FormCompose';
import FormAddresses from './screens/Projects/Form/Form/FormAddresses';
import FormResults from './screens/Projects/Form/Form/FormResults';
import FormsMenu from './screens/Projects/Form/FormsMenu';
import FormMenu from './screens/Projects/Form/Menu/FormMenu';
import FormsStatus from './screens/Projects/Form/FormsStatus';
// import FormSetup from './screens/Projects/Form/Form/FormSetup';
// import FormAddresses from './screens/Projects/Form/Form/FormAddresses';
// import FormResults from './screens/Projects/Form/Form/FormResults';

import './App.css';

class App extends Component {

  constructor()
  {
      super();

      this.checkInternetConnection();
  }

  componentWillUnmount()
  {
      if(this.internet_polling)
      {
          clearTimeout(this.internet_polling);
      }
  }

  //
  internet_polling = null; // null | object
  internet_connection = true; // true | false
  application_up_to_date = true; // true | false

  async checkInternetConnection()
  {
      var condition = navigator.onLine ? 'online' : 'offline';

      if(condition == 'online')
      {   // check server connection
          if(this.checkCobeeServerConnection())
          {
              this.internetIsOnline();
          }
          else
          {
              this.internetIsOffline();
          }
      }
      else
      {
          this.internetIsOffline();
      }

      if(this.internet_polling)
      {
          clearTimeout(this.internet_polling);
      }

      var this_obj = this;

      //
      this.internet_polling = setTimeout(() => { this_obj.checkInternetConnection(); }, 5000);
  }

  internetIsOffline()
  {
      if(this.internet_connection)
      {
          alert('Er kan geen verbinding worden gemaakt met de server. Controleer de internet verbinding.');
      }

      this.internet_connection = false;
  }

  internetIsOnline()
  {
      if(!this.internet_connection)
      {
          alert('De verbinding met de server is hersteld.');
      }

      this.internet_connection = true;
  }

  async checkCobeeServerConnection()
  {
      const timeout = new Promise((resolve, reject) => {

          setTimeout(reject, 5000, 'Request timed out');
      });

      //
      const request = fetch(API_URL+'/polling');

      try
      {
          const response = await Promise.race([timeout, request]);

          this.checkCobeeVersion(response.headers);

          return true;
      }
      catch(error)
      {
          return false;
      }
  }

  async checkCobeeVersion(headers)
  {
      var api_version = headers.get('x-cobee-backend-version');

      // newer version available
      if(api_version && COBEE_VERSION && api_version.localeCompare(COBEE_VERSION, undefined, { numeric: true, sensitivity: 'base' }))
      {
          if(this.application_up_to_date)
          {
              this.application_up_to_date = false; // mark as no longer up to date

              // show update message
              Modal.confirm({
                  title: 'Nieuwe versie beschikbaar',
                  content: 'Klik op de "Toepassen" knop om gebruik te maken van de nieuwe versie. Maar let op: je zal opnieuw in moeten loggen. Klik op "Annuleren" om later gebruik te maken van de nieuwe versie.',
                  okText: 'Toepassen',
                  cancelText: 'Annuleren',
                  onOk(){
                      window.location.href = '/update';
                  },
                  onCancel(){},
              });
          }
      }
  }

  //
  render() {

      var meta_tags = document.getElementsByTagName("meta");

      var robots_meta_tag_found = false;

      if(meta_tags)
      {
          for(let meta_tag of meta_tags)
          {
              if(meta_tag.getAttribute('name') == 'robots')
              {
                  robots_meta_tag_found = true;
              }
          }
      }

      if(!robots_meta_tag_found)
      {
          document.head.innerHTML += '<meta name="robots" content="noindex,nofollow" />';
      }

    return (
      <div className="App">
        <Router>
          <Switch>
            <AuthenticatedRoute exact path="/403" component={Status403} {...this.props} />
            <AuthenticatedRoute exact path="/500" component={Status500} {...this.props} />
            <AuthenticatedRoute exact path="/error" component={ErrorPage} {...this.props} />

            <Route exact path="/login/token/:token/idp/:idp" component={Login} />
            <Route exact path="/login/token/:token" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/password/reset/:token/:email" component={Reset} />
            <Route exact path="/password-forget" component={Forget} />

            <Route exact path="/" component={Refresh} {...this.props} />
            <Route exact path="/update" component={Update} {...this.props} />

            <Route exact path="/change/point/:id/:hash" component={ChangePointStatus} {...this.props} />
            <Route exact path="/change/point/:id/:hash/status/:status" component={ChangePointStatus} {...this.props} />

            <Route exact path="/kyp/oauth" component={KypOauth} {...this.props} />

            <AuthenticatedRoute exact path="/projects" component={Projects} {...this.props} />

            <AuthenticatedRoute exact path="/administration" component={AdministrationTransactions} {...this.props} />
            <AuthenticatedRoute exact path="/administration/choice-module" component={ChoiceTransactions} {...this.props} />
            <AuthenticatedRoute exact path="/administration/price-calculator" component={PriceCalculator} {...this.props} />

            <AuthenticatedRoute exact path="/accounts" component={Accounts} />
            <AuthenticatedRoute exact path="/accounts/:id/detail" component={AccountsDetail} />
            <AuthenticatedRoute exact path="/accounts/:id/profile" component={AccountsProfile} />
            <AuthenticatedRoute exact path="/accounts/add" component={AccountsDetail} />
            <AuthenticatedRoute exact path="/account-roles" component={AccountRoles} />
            <AuthenticatedRoute exact path="/account-roles/:id/detail" component={AccountRolesDetail} />
            <AuthenticatedRoute exact path="/account-roles/add" component={AccountRolesDetail} />
            <AuthenticatedRoute exact path="/delivery-users" component={DeliveryUsers} />
            <AuthenticatedRoute exact path="/delivery-user/:id/detail" component={DeliveryUserDetail} />
            <AuthenticatedRoute exact path="/delivery-users/add" component={DeliveryUserDetail} />
            <AuthenticatedRoute exact path="/appointment-users" component={AppointmentUsers} />
            <AuthenticatedRoute exact path="/appointment-user/:id/detail" component={AppointmentUserDetail} />
            <AuthenticatedRoute exact path="/appointment-users/add" component={AppointmentUserDetail} />


            <AuthenticatedRoute exact path="/library" component={Library} />
            <AuthenticatedRoute exact path="/library/categories" component={LibraryCategory} />
            <AuthenticatedRoute exact path="/library/add" component={LibraryDetail} />
            <AuthenticatedRoute exact path="/library/:id/detail" component={LibraryDetail} />
            <AuthenticatedRoute exact path="/library/category/:id" component={LibraryCategoryDetail} />

            <AuthenticatedRoute exact path="/library/partners" component={GlobalPartners} />
            <AuthenticatedRoute exact path="/library/partner/add" component={GlobalPartnerDetail} />
            <AuthenticatedRoute exact path="/library/partner/:partnerid/detail" component={GlobalPartnerDetail} />

            <AuthenticatedRoute exact path="/library/clients" component={Clients} />
            <AuthenticatedRoute exact path="/library/client/add" component={ClientDetail} />
            <AuthenticatedRoute exact path="/library/client/:clientid/detail" component={ClientDetail} />

            <AuthenticatedRoute exact path="/library/corporate-identity/" component={CorporateIdentity} />
            <AuthenticatedRoute exact path="/library/corporate-identity/add" component={CorporateIdentityDetail} />
            <AuthenticatedRoute exact path="/library/corporate-identity/:id/detail" component={CorporateIdentityDetail} />

            <AuthenticatedRoute exact path="/library/information-texts/" component={InformationTexts} />
            <AuthenticatedRoute exact path="/library/information-texts/categories" component={InformationTextsCategories} />
            <AuthenticatedRoute exact path="/library/information-text/add" component={InformationTextDetail} />
            <AuthenticatedRoute exact path="/library/information-text/:informationid/detail" component={InformationTextDetail} />
            <AuthenticatedRoute exact path="/library/information-text/category/:id" component={InformationTextCategoryDetail} />

            <AuthenticatedRoute exact path="/library/intake" component={LibraryIntake} />
            <AuthenticatedRoute exact path="/library/intake/add" component={IntakeDetail} />
            <AuthenticatedRoute exact path="/library/intake/:id/detail" component={IntakeDetail} />

            <AuthenticatedRoute exact path="/library/delivery" component={LibraryDelivery} />
            <AuthenticatedRoute exact path="/library/delivery/add" component={LibraryDeliveryDetail} />
            <AuthenticatedRoute exact path="/library/delivery/:id/detail" component={LibraryDeliveryDetail} />
            <AuthenticatedRoute exact path="/library/delivery/:id/settings" component={LibraryDeliverySettings} />
            <AuthenticatedRoute exact path="/library/delivery/:id/projects" component={LibraryDeliveryProjects} />

            <AuthenticatedRoute exact path="/library/support-base" component={LibrarySupportBase} />
            <AuthenticatedRoute exact path="/library/support-base/add" component={SupportBaseDetail} />
            <AuthenticatedRoute exact path="/library/support-base/:id/detail" component={SupportBaseDetail} />

            <AuthenticatedRoute exact path="/library/envelopes" component={LibraryEnvelopes} />
            <AuthenticatedRoute exact path="/library/envelope/add" component={EnvelopeDetail} />
            <AuthenticatedRoute exact path="/library/envelope/:id/detail" component={EnvelopeDetail} />

            <AuthenticatedRoute exact path="/library/surveys" component={LibrarySurveys} />
            <AuthenticatedRoute exact path="/library/survey/add" component={LibrarySurveyDetail} />
            <AuthenticatedRoute exact path="/library/survey/:id/detail" component={LibrarySurveyDetail} />

            <AuthenticatedRoute exact path="/library/choice" component={LibraryChoice} />
            <AuthenticatedRoute exact path="/library/choice/add" component={ChoiceDetail} />
            <AuthenticatedRoute exact path="/library/choice/:id/detail" component={ChoiceDetail} />

            <AuthenticatedRoute exact path="/library/contacts" component={ContactsLibrary} />
            <AuthenticatedRoute exact path="/library/contacts/add" component={ContactsLibraryDetail} />
            <AuthenticatedRoute exact path="/library/contacts/:id/detail" component={ContactsLibraryDetail} />

            <AuthenticatedRoute exact path="/library/delivery-users" component={DeliveryUsersLibrary} />
            <AuthenticatedRoute exact path="/library/delivery-user/add" component={DeliveryUserLibraryDetail} />
            <AuthenticatedRoute exact path="/library/delivery-user/:id/detail" component={DeliveryUserLibraryDetail} />

            <AuthenticatedRoute exact path="/library/forms" component={LibraryForms} />
            <AuthenticatedRoute exact path="/library/form/add" component={LibraryFormDetail} />
            <AuthenticatedRoute exact path="/library/form/:id/detail" component={LibraryFormDetail} />
            <AuthenticatedRoute exact path="/library/form/:id/settings" component={LibraryFormSettings} />
            <AuthenticatedRoute exact path="/library/form/:id/projects" component={LibraryFormProjects} />

            <AuthenticatedRoute exact path="/media" component={Media} />
            <AuthenticatedRoute exact path="/media/add" component={MediaDetail} />
            <AuthenticatedRoute exact path="/media/:id/detail" component={MediaDetail} />
            <AuthenticatedRoute exact path="/media/categories" component={MediaCategories} />
            <AuthenticatedRoute exact path="/media/category/:id" component={MediaCategory} />

            <AuthenticatedRoute exact path="/profile" component={Profile} />
            <AuthenticatedRoute exact path="/settings" component={Settings} />
            <AuthenticatedRoute exact path="/settings/general" component={Settings} />
            <AuthenticatedRoute exact path="/settings/client" component={SettingsClient} />

            <AuthenticatedRoute exact path="/projects/:id/media" component={ProjectMedia} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/media/add" component={ProjectMediaDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/media/:media_id/detail" component={ProjectMediaDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/media/categories" component={ProjectMediaCategories} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/media/category/:category_id" component={ProjectMediaCategory} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/modules/chat" component={Chat} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/chat/settings" component={ChatSettings} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/workorder" component={Workorder} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/workorder/settings" component={WorkorderSettings} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/information" component={Information} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/information/menu" component={InformationMenu} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/information/menu/add" component={InformationMenuDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/information/menu/:informationmenuitemid/detail" component={InformationMenuDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/information/add" component={InformationDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/information/:informationitemid/detail" component={InformationDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/information/:informationitemid/addresses" component={InformationAddresses} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/news" component={News} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/news/add" component={NewsDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/news/:newsid/detail" component={NewsDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/news/:newsid/addresses" component={NewsAddresses} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/complaints" component={Complaints} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/complaints/add" component={ComplaintsDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/complaints/:complaintid/detail" component={ComplaintsDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/complaints/:complaintid/addresses" component={ComplaintsAddresses} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/documents" component={Documents} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/documents/add" component={DocumentDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/document/:documentid/detail" component={DocumentDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/document/:documentid/addresses" component={DocumentAddresses} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/contacts" component={Contacts} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/contacts/add" component={ContactDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/contacts/:contactid/detail" component={ContactDetail} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/enquete" component={Survey} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/add" component={SurveyDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/:surveyid/detail" component={SurveyDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/:surveyid/questions" component={SurveyQuestions} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/:surveyid/questions-v2" component={SurveyQuestionsV2} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/:surveyid/question/add" component={SurveyQuestion} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/:surveyid/question/:questionid" component={SurveyQuestion} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/:surveyid/addresses" component={SurveyAddresses} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/:surveyid/results" component={SurveyResults} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/enquete/:surveyid/results-v2" component={SurveyResultsV2} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/partners" component={Partners} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/partners/add" component={PartnerDetail} sidebar={true}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/partner/:partnerid/detail" component={PartnerDetail} sidebar={true}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/schedule" component={ScheduleSettings} sidebar={true} step={1}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/blocked-dates/" component={ScheduleBlockedDates} sidebar={true} step={2}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/blocked-dates/add/" component={ScheduleBlockedDates} sidebar={true} step={2}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/kyp-addresses" component={ScheduleKypAddresses} sidebar={true} step={2}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/kyp-information-items" component={ScheduleKypInformationItems} sidebar={true} step={3}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/kyp-status" component={ScheduleKypStatus} sidebar={true} step={4}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/day-schedule/" component={ScheduleDaySchedule} sidebar={true} step={3}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/calendar/" component={ScheduleCalendar} sidebar={true} step={4}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/day-schedule/add" component={ScheduleDayScheduleDetail} sidebar={true} step={3}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/day-schedule/:dayscheduleid/detail" component={ScheduleDayScheduleDetail} sidebar={true} step={3}/>
            <AuthenticatedRoute exact path="/projects/:id/modules/schedule/export/" component={ScheduleExport} sidebar={true} step={5}/>

            <AuthenticatedRoute exact path="/projects/:id/modules/appointments" component={Appointments} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/appointment/add" component={AppointmentDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/appointment/:appointmentid/detail" component={AppointmentDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/appointment/:appointmentid/addresses" component={AppointmentAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/appointment/:appointmentid/availability" component={AppointmentAvailability} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/appointment/:appointmentid/clusters" component={AppointmentClusters} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/appointment/:appointmentid/data" component={AppointmentData} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/appointment/:appointmentid/overview" component={AppointmentOverview} sidebar={true} />
            {/*<AuthenticatedRoute exact path="/projects/:id/modules/appointment/:appointmentid/agenda" component={AppointmentAgenda} sidebar={true} />*/}

            <AuthenticatedRoute exact path="/projects/:id/modules/appointments/people" component={AppointmentsUsers} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/appointments/person/:user_id" component={AppointmentsUser} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/modules/appointments/status" component={AppointmentsStatus} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/modules/appointments/calendar" component={AppointmentsCalendar} sidebar={true} />

            {/*<AuthenticatedRoute exact path="/projects/:id/modules/forms" component={Forms} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/form/add" component={FormSetup} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/form/:form_id" component={FormSetup} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/form/:form_id/addresses" component={FormAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/modules/form/:form_id/results" component={FormResults} sidebar={true} />/**/}

            <AuthenticatedRoute exact path="/projects/:id/forms" component={Forms} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/form/add" component={FormSettings} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/form/:form_id" component={FormSettings} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/form/:form_id/compose" component={FormCompose} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/form/:form_id/addresses" component={FormAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/form/:form_id/results" component={FormResults} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/forms/menu" component={FormsMenu} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/form/menu/add" component={FormMenu} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/form/menu/:menu_id" component={FormMenu} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/forms/status" component={FormsStatus} sidebar={true} />

            <AuthenticatedRoute exact path="/customers" component={Customer} />
            <AuthenticatedRoute exact path="/customers/add" component={CustomerGeneral} />
            <AuthenticatedRoute exact path="/customers/:id/general" component={CustomerGeneral} />
            <AuthenticatedRoute exact path="/customers/:id/personalize" component={CustomerPersonalize} />
            <AuthenticatedRoute exact path="/customers/:id/connections" component={CustomerConnections} />
            <AuthenticatedRoute exact path="/customers/:id/modules" component={CustomerModules} />
            <AuthenticatedRoute exact path="/customers/:id/license" component={CustomerLicense} />
            <AuthenticatedRoute exact path="/customers/:id/billing" component={CustomerBilling} />
            <AuthenticatedRoute exact path="/customers/:id/transactions" component={CustomerTransactions} />

            <AuthenticatedRoute exact path="/projects" component={Projects} />
            <AuthenticatedRoute exact path="/projects/add" component={ProjectDetail} />
            <AuthenticatedRoute exact path="/projects/:id/dashboard" component={ProjectsDashboard} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/general" component={ProjectsSettings} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/personalize" component={ProjectsPersonalize} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/transactions" component={ProjectTransactions} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/users" component={ProjectsUsers} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/user/add" component={ProjectUser} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/user/:userid" component={ProjectUser} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/modules" component={ProjectsModules} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/users" component={ProjectsUsers} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/intake" component={ProjectIntake} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/intake/add" component={ProjectIntakeDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/intake/:intakeid" component={ProjectIntakeDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/intake/:intakeid/addresses" component={ProjectIntakeAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/intake/:intakeid/results" component={ProjectIntakeResults} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/intake/:intakeid/result/:resultid" component={ProjectIntakeResult} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/support-base/forms" component={ProjectSupportBase} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/addresses/:form_id" component={SupportBaseFormAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/compose/:form_id" component={SupportBaseFormCompose} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/:form_id/correspondence/form" component={SupportBaseCorrespondenceForm} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/:form_id/correspondence/letter" component={SupportBaseCorrespondenceLetter} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/:form_id/correspondence/email" component={SupportBaseCorrespondenceEmail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/:form_id/correspondence/attachments" component={SupportBaseCorrespondenceAttachments} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/:form_id/correspondence/check" component={SupportBaseCorrespondenceCheck} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/send/:form_id" component={SupportBaseFormSend} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/form/:form_id/correspondence/status" component={SupportBaseCorrespondenceStatus} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/support-base/settings" component={ProjectSupportBaseSettings} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/support-base/reminders" component={ProjectSupportBaseReminders} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/settings/:reminder_id" component={SupportBaseReminder} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/letter/:reminder_id" component={SupportBaseReminderLetter} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/email/:reminder_id" component={SupportBaseReminderEmail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/check/:reminder_id" component={SupportBaseReminderCheck} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/status" component={ProjectSupportBaseStatus} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/support-base/resend/addresses/:form_id" component={SupportBaseResendAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/resend/compose/:form_id" component={SupportBaseResendCompose} sidebar={true} />
            {/*<AuthenticatedRoute exact path="/projects/:id/support-base/resend/:form_id/correspondence/form" component={SupportBaseResendCorrespondenceForm} sidebar={true} />*/}
            <AuthenticatedRoute exact path="/projects/:id/support-base/resend/:form_id/correspondence/letter" component={SupportBaseResendCorrespondenceLetter} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/resend/:form_id/correspondence/email" component={SupportBaseResendCorrespondenceEmail} sidebar={true} />
            {/*<AuthenticatedRoute exact path="/projects/:id/support-base/resend/:form_id/correspondence/attachments" component={SupportBaseResendCorrespondenceAttachments} sidebar={true} />*/}
            <AuthenticatedRoute exact path="/projects/:id/support-base/resend/:form_id/correspondence/check" component={SupportBaseResendCorrespondenceCheck} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/resend/send/:form_id" component={SupportBaseFormResend} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/addresses/:form_id" component={SupportBaseReminderAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/compose/:form_id" component={SupportBaseReminderCompose} sidebar={true} />
            {/*<AuthenticatedRoute exact path="/projects/:id/support-base/reminder/:form_id/correspondence/form" component={SupportBaseReminderCorrespondenceForm} sidebar={true} />*/}
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/:form_id/correspondence/letter" component={SupportBaseReminderCorrespondenceLetter} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/:form_id/correspondence/email" component={SupportBaseReminderCorrespondenceEmail} sidebar={true} />
            {/*<AuthenticatedRoute exact path="/projects/:id/support-base/reminder/:form_id/correspondence/attachments" component={SupportBaseReminderCorrespondenceAttachments} sidebar={true} />*/}
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/:form_id/correspondence/check" component={SupportBaseReminderCorrespondenceCheck} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/support-base/reminder/send/:form_id" component={SupportBaseFormReminder} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/deliver/points" component={DeliverPoints} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/points/archive" component={DeliverPoints} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/modal-point/:point_id" component={DeliverPoints} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/modal-point/:point_id/:parent_id" component={DeliverPoints} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/settings" component={DeliverSettings} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/notifications" component={DeliverPointsNotifications} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/notifications/archive" component={DeliverPointsNotifications} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/point/:point_id" component={DeliverPoint} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/point/:point_id/:parent_id" component={DeliverPoint} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/drawings" component={DeliverDrawings} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/drawings/archive" component={DeliverDrawings} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/deliveries" component={Deliveries} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/deliveries/archive" component={Deliveries} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/delivery/:delivery_id" component={DeliveryDetails} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/delivery/:delivery_id/addresses" component={DeliveryAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/users" component={DeliverUsers} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/users/archive" component={DeliverUsers} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/informed-users" component={DeliverInformedUsers} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/delivery-forms" component={DeliveryForms} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/delivery-form/:form_id" component={DeliveryFormSettings} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/delivery-form/:form_id/deliveries" component={DeliveryFormDeliveries} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/delivery-form/:form_id/form" component={DeliveryForm} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/delivery-form/:form_id/points" component={DeliveryFormPoints} sidebar={true} />
            {/*<AuthenticatedRoute exact path="/projects/:id/deliver/delivery-form/:form_id/points" component={DeliveryFormPoints} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/deliver/delivery-form/:form_id/status" component={DeliveryFormStatus} sidebar={true} />*/}
            <AuthenticatedRoute exact path="/projects/:id/deliver/status" component={DeliverStatus} sidebar={true} />


            <AuthenticatedRoute exact path="/projects/:id/choice/settings" component={ChoiceSettings} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/choice/forms" component={ChoiceForms} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/choice/form/compose/:form_id" component={ChoiceFormCompose} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/choice/form/addresses/:form_id" component={ChoiceFormAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/choice/form/status/:form_id" component={ChoiceFormStatus} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/choice/status" component={ChoiceFormsStatus} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/choice/form/settings/:form_id" component={ChoiceFormSettings} sidebar={true} />

            {/*<AuthenticatedRoute exact path="/projects/:id/exports" component={FuseBoxList} sidebar={true} />*/}
            <AuthenticatedRoute exact path="/projects/:id/exports" component={Export} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/exports/combined" component={DemoCombinedExport} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/exports/:export" component={Export} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/addresses" component={ProjectsAddresses} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/add" component={ProjectsAddressesGeneral} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/import" component={ProjectsAddressesImport} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/import/zipcode" component={ProjectsAddressesZipcodeImport} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/import/map" component={ProjectsAddressesMapImport} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/general" component={ProjectsAddressesGeneral} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/contacts" component={ProjectsAddressesContactpersons} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/contacts/add" component={ProjectsAddressesContactpersonsDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/contacts/:contactsid/detail" component={ProjectsAddressesContactpersonsDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/dossier" component={ProjectsAddressesDossier} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/dossier/note" component={ProjectsAddressesDossierNote} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/dossier/note/:noteid" component={ProjectsAddressesDossierNote} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/fields" component={ProjectAddressFieldValues} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/address-fields" component={ProjectAddressFields} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/address-field/:address_field_id" component={ProjectAddressField} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/addresses/:addressid/bag-data" component={AddressBagData} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/pushnotification" component={ProjectsPushNotification} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/pushnotification/add" component={AddPublicPush} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/pushnotification/:pushnotificationid/detail" component={AddPublicPush} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/pushnotification/:pushnotificationid/send" component={SendPublicPush} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/pushnotification/:pushnotificationid/status" component={StatusPublicPush} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/correspondence" component={ProjectsCorrespondence} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/add" component={ProjectsCorrespondenceSetup} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/add/:type/:typeid/:title" component={ProjectsCorrespondenceSetup} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/setup" component={ProjectsCorrespondenceSetup} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose" component={ProjectsCorrespondenceCompose} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/tab/:tab" component={ProjectsCorrespondenceCompose} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/mail/:type" component={ComposeMail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/:type/address/:addressid" component={ComposeMail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/:type/occupant/:occupantid" component={ComposeMail} sidebar={true} />

            {/*<AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/email/email/:type" component={ComposeEmail} sidebar={true} />*/}
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/email/occupant/:occupantid/:type" component={ComposeEmail} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/sms" component={ComposeSMS} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/sms/occupant/:occupantid" component={ComposeSMS} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/push" component={ComposePush} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/push/occupant/:occupantid" component={ComposePush} sidebar={true} />

            {/*<AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/mail/mail" component={ComposeMail} sidebar={true} />*/}
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/:type/mail/occupant/:occupantid" component={ComposeMail} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/email/:type" component={ComposeEmail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/compose/email/email/occupant/:occupantid/:type" component={ComposeEmail} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/send" component={ProjectsCorrespondenceSend} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/status" component={ProjectsCorrespondenceStatus} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/correspondence/:correspondenceid/status/tab/:tab" component={ProjectsCorrespondenceStatus} sidebar={true} />

            <AuthenticatedRoute exact path="/projects/:id/groups" component={ProjectsGroups} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/groups/add" component={ProjectsGroupsDetail} sidebar={true} />
            <AuthenticatedRoute exact path="/projects/:id/groups/:groupid/detail" component={ProjectsGroupsDetail} sidebar={true} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const AuthenticatedRoute = ({ component: Component, sidebar: sidebarWrapper, ...rest }) => (
  <Route {...rest} render={ props => (
    localStorage.getItem('authToken') ? (
      <AppLayout component={<Component {...props} />} sidebar={sidebarWrapper} />
    ) : (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }}/>
    )
  )}/>
)

class AuthenticatedRouteTest extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
    }
  }
  componentDidMount() {
    this.props.getLoginUserAction().then((response) =>{
      if(response) {
        this.setState({isLoggedIn: true});
      } else {
        this.props.history.push('/');
      }
    });
  }

  render() {

    return (
      <Route render={() => (
        <AppLayout component={(<this.props.component sidebar={this.props.sidebar}/>)} />
      )} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching: state.auth.isFetching,
      isError: state.auth.isError,
      auth: state.auth.user,
      customers: state.auth.customers,
      selectedCustomer: state.auth.selectedCustomer,
    };
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        getLoginUserAction : bindActionCreators(getLoginUserAction, dispatch),
    }
  };
//connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRouteTest);

export default connect(mapStateToProps, mapDispatchToProps)(App);
