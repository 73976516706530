import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Steps } from 'antd';

class ScheduleKypSteps extends React.Component {

  onChange = current => {

    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule`);
    }

    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/kyp-addresses`);
    }

    if(current == 2) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/kyp-information-items`);
    }

    if(current == 3) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/kyp-status`);
    }
  }

  render () {
    return (
      <Steps type="navigation" size="small" current={this.props.current} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{ padding: "10px 30px" }} title="Instellingen" />
        <Steps.Step style={{ padding: 10, marginLeft: 25 }} title="Adressen" />
        <Steps.Step style={{ padding: 10, marginLeft: 25 }} title="Informatie" />
        <Steps.Step style={{ padding: 10, marginLeft: 25 }} title="Status" />
      </Steps>
    );
  }
}

export default ScheduleKypSteps;
