import React, { Component } from 'react';
// import '@ant-design/compatible/assets/index.css';
// import { Form } from '@ant-design/compatible';
import { Row, Col, Card, Select, Button, Tag, Checkbox, Tooltip, Switch } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../../utils/Notifications';
import ScheduleKypSteps from '../Steps/ScheduleKypSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { updateScheduleSettingsAction, getKypProjectAddressesAction, refreshKypProjectDataAction, getScheduleAction } from '../../../../../actions/scheduleActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getProjectAction } from '../../../../../actions/projectActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { InfoCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';

class ScheduleKypAddresses extends Component
{
  state = {
      cobee_kyp_addresses: [],
      loading: true,
      selectedRowKeys: [],

      kyp_bouwnummer: null,
      selected_addresses_bouwnrs: [],
      addresses: [],
      refresh: false,

      all_checked: false,

      group_filter: [],
      group_filter_and: true,

      nr_of_results: 0
    };

  filtered_addresses = null;

  componentDidMount()
  {
    this.load();
  }

  load()
  {
    this.setState({ loading: true });

    this.props.getProjectAction(this.props.match.params.id).then(() => {

      this.props.getGroupsAction({ projectId: this.props.match.params.id });

      this.props.getAddressesAction({ projectId: this.props.match.params.id }).then(() => {

        var addresses = [];

        this.setState({ nr_of_results: this.props.addresses.length });

        this.props.addresses.map((address) => {

          address.construction_number = address.construction_number ? JSON.parse(address.construction_number) : [];

          addresses.push(address);
        });

        //
        this.setState({ addresses: addresses }, () => {

          //
          var cobee_kyp_addresses = [];

          this.state.addresses.map((address) => {

              cobee_kyp_addresses[''+address.id] = address.construction_number;
          });

          this.setState({ cobee_kyp_addresses: cobee_kyp_addresses });
        });

      });

      this.props.getScheduleAction(this.props.match.params.id).then(() => {});

      if(this.props.project.kyp_project_id)
      {
        this.props.getKypProjectAddressesAction(this.props.project.id, this.props.project.kyp_project_id).then(() => {

          this.setState({ loading: false });
        });
      }
      else
      {
        this.setState({ loading: false });
      }
    });
  }

  refreshKypProjectData()
  {
    this.props.refreshKypProjectDataAction(this.props.project.id).then((status) => {

      if(status)
      {
        showSuccess();

        this.load();
      }
      else
      {
        showError('Wacht even met het opnieuw proberen.');
      }
    });
  }

  saveSettings(e)
  {
    var values = {};

    var cobee_kyp_addresses = [];

    this.state.addresses.map((address) => {
      cobee_kyp_addresses[''+address.id] = {
        id: address.id,
        construction_number: address.construction_number,
        schedule_enabled: address.schedule_enabled ? true : false
      }
    });

    values.cobee_kyp_addresses = cobee_kyp_addresses;

    //
    this.props.updateScheduleSettingsAction(this.props.match.params.id, values).then(() => {

      showSuccess('Succesvol opgeslagen');

      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/kyp-information-items`);
    });
  }

  onSelectChange = (selectedRowKeys) => {

      this.setState({ selectedRowKeys });
  }

  /**
   *
   */
  selectedKypBouwnrForAddress(value, id)
  {
    var addresses = this.state.addresses;

    addresses.map((address, index) => {

      if(address.id == id)
      {
        address.construction_number = value;
      }
    });

    this.setState({ addresses: addresses });
  }

  /**
   * 
   */
  selectKypBouwnr(value)
  {
    this.setState({ kyp_bouwnummer: value });

    if(value)
    { // pre select addresses with value selected for bouwnr
      var selected_address_ids = [];

      this.state.addresses.map((address) => {

        var selected_bouwnummers_address = address.construction_number;

        selected_bouwnummers_address && selected_bouwnummers_address.map && selected_bouwnummers_address.map((bouwnr) => {

          if(bouwnr.toLowerCase() == value.toLowerCase())
          {
            selected_address_ids.push(address.id);
          }
        });
      });

      this.setState({ selectedRowKeys: selected_address_ids });
    }
    else
    {
      this.setState({
        selectedRowKeys: []
      });
    }
  }

  /**
   * update selected addresses with KYP bouwnr
   * & remove KYP bouwnr from deselected addresses
   */
  updateKypBouwnummers()
  {
    var addresses = this.state.addresses;

    this.state.addresses.map((address) => {

      var selected_bouwnummers_address = [];

      if(!address.construction_number || !address.construction_number.map)
      {
        address.construction_number = [];
      }

      address.construction_number.map((value) => {

        if(value.toLowerCase() != this.state.kyp_bouwnummer.toLowerCase())
        { // add other value
          selected_bouwnummers_address.push(value);
        }
        else if(this.state.selectedRowKeys.includes(address.id))
        { // keep value to selected address
          selected_bouwnummers_address.push(value);
        }
        // else do not add value
      });

      if(this.state.selectedRowKeys.includes(address.id) && !selected_bouwnummers_address.includes(this.state.kyp_bouwnummer))
      { // add new value
        selected_bouwnummers_address.push(this.state.kyp_bouwnummer);
      }

      address.construction_number = selected_bouwnummers_address;

    });

    //
    this.setState({ refresh: true }, () => {

      this.setState({
        addresses: addresses,
        // refresh: false
      }, () => { this.setState({ refresh: false }); });

        // save settings (? & reload)

        var values = {};

        var cobee_kyp_addresses = [];
    
        this.state.addresses.map((address) => {
          cobee_kyp_addresses[''+address.id] = {
            id: address.id,
            construction_number: address.construction_number,
            schedule_enabled: address.schedule_enabled ? true : false
          }
        });
    
        // values.cobee_kyp_addresses = JSON.stringify(cobee_kyp_addresses);
        values.cobee_kyp_addresses = cobee_kyp_addresses;
    
        //
        this.props.updateScheduleSettingsAction(this.props.match.params.id, values).then(() => {
    
          showSuccess('Succesvol opgeslagen');

          this.setState({ 
            kyp_bouwnummer: null,
            selectedRowKeys: []
          });
        });

    });
  }

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  handleTableChange = (pagination, filters, sorter, extra) => {

    console.log('handleTableChange', extra);

    this.setState({
      pagination: pagination,
      filteredInfo: filters,
      sortedInfo: sorter,
      extra: extra
    });

    this.setState({ nr_of_results: extra.currentDataSource.length }); // (this.filtered_addresses ? this.filtered_addresses.length : this.props.addresses.length) });
  }

  toggleEnableScheduleForSelectedAddresses(checked, specific_address_id = false)
  {
    var addresses = this.state.addresses;

    if(!specific_address_id)
    {
      var filtered_addresses = (this.filtered_addresses ? this.filtered_addresses : this.state.addresses);

      addresses.map((address) => {

        filtered_addresses.map((filtered_address) => {

          if(address.id == filtered_address.id)
          {
            address.schedule_enabled = (checked ? true : false);
          }
        });
      });
    }
    else
    {
      addresses.map((address) => {

        if(address.id == specific_address_id)
        {
          address.schedule_enabled = (checked ? true : false);
        }
      });
    }

    this.setState({ refresh: true }, () => {

      this.setState({ 
        addresses: addresses,
        // refresh: false
      }, () => { this.setState({ refresh: false }); });
    });
  }

  allChecked()
  {
    if(this.filtered_addresses && this.filtered_addresses.length && this.filtered_addresses.length == this.state.selectedRowKeys.length)
    {
      // return true;
      this.setState({ all_checked: true });
    }
    else if(this.state.addresses.length && this.state.addresses.length == this.state.selectedRowKeys.length)
    {
      // return true;
      this.setState({ all_checked: true });
    }
    else
    {
      // return false;
      this.setState({ all_checked: false });
    }
  }

  removeItem(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = (record.groups === undefined ? true : false);

                  record.groups && record.groups.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = (record.groups === undefined ? true : false);

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.groups && record.groups.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  render()
  {    
    if(this.state.loading){ return null; }

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        title: 'Adres',
        dataIndex: 'full_address',
        render: (text, address) => (
          <span>{text}</span>
        )
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        // filters: this.props.groups && this.props.groups.map((elem) => {
        //   return {
        //     text: elem.name,
        //     value: elem.name,
        //   }
        // }),
        filterMultiple: true,
        // onFilter: (value, record) => this.groupsFilter(value, record),
        // filteredValue: filteredInfo.groups || null,

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 0 }}>
  
            <div>
                {this.props.groups && this.props.groups.map((obj) => {
                    return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                        <Checkbox
                            onChange={(e) => {
                                var my_group_filter = this.state.group_filter;
  
                                if(e.target.checked == true){ my_group_filter.push(obj.name); }
                                else{ this.removeItem(my_group_filter, obj.name); }
  
                                this.setState({ group_filter: my_group_filter });
  
                                setSelectedKeys(my_group_filter);
                            }}
                            checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                            >
                            {obj.name}
                        </Checkbox>
                    </div>
                })}
            </div>
  
            <div style={{padding: 8, width: '100%', clear: 'both'}}>
                <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
                <div style={{float: 'left', marginLeft: 8}}>filter</div>
            </div>
  
            <div style={{width: '100%', clear: 'both'}}>
                <Button
                  type="link"
                  onClick={() => { confirm(); }}
                  style={{marginLeft: -8, marginRight: 8 }}
                  >OK</Button>
  
                <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
            </div>
  
          </div>
        ),
        onFilter: (value, record) => this.groupsFilter(value, record),

        render: (text, record) => record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      },
      {
        // title: 'Planning zichtbaar',
        title: () => { return <span>
          <Checkbox 
            // checked={this.allChecked() ? true : false} 
            checked={this.state.all_checked}
            style={{ marginRight: 18 }} 
            onClick={(e) => { 
              
              this.setState({ all_checked: !this.state.all_checked /*e.target.value*/ });

              this.toggleEnableScheduleForSelectedAddresses(e.target.checked);
            }}
            />
          Planning tonen
          <Tooltip className="mhs" overlayStyle={{ whiteSpace: 'pre-line' }} title={"Met deze optie kan je aangeven of de KYP planning voor de bewoner op Cobee getoond moet worden"}>
            <InfoCircleTwoTone />
          </Tooltip>
        </span>},
        dataIndex: 'schedule_enabled',
        width: 190,
        render: (text, address) => (
          <span>
            <Checkbox
              name={'schedule_enabled[' + address.id + ']'}
              checked={address.schedule_enabled ? true : false} 
              onChange={(e) => { this.toggleEnableScheduleForSelectedAddresses(e.target.checked, address.id); }} 
              />
          </span>
        )
      },
      {
        title: 'KYP bouwnummer(s)',
        dataIndex: 'construction_number',
        render: (text, address) => (
          <span>
            <Select
              name={'construction_number[' + address.id + ']'}
              value={address.construction_number}
              onChange={(value) => {
                this.selectedKypBouwnrForAddress(value, address.id);
              }}
              mode="multiple"
              style={{ width: '100%' }}
              >
              <Select.Option value={''}></Select.Option>
              { this.props.kyp_addresses.map((kyp_address) => {
                  return <Select.Option value={''+kyp_address}>{kyp_address}</Select.Option>
              }) }
            </Select>
          </span>
        ),
      }
    ];

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={(e) => this.saveSettings(e) }>Opslaan en volgende</Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleKypSteps current={1} {...this.props} />
          </Card>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Card>

                { this.props.project.kyp_project_id ? <Row gutter={24} style={{ marginBottom: 24 }}>
                  <Col span={24}>
                    <Button /*type={'new'}*/ style={{ /*marginRight: 12*/ }} onClick={() => { this.refreshKypProjectData(); }}>Planning ophalen</Button>
                    <Tooltip className="mhs" overlayStyle={{ whiteSpace: 'pre-line' }} title={"Met deze knop kan je de meest actuele versie van de planning in KYP ophalen en bijwerken in Cobee. De planning kan maximaal één maal per minuut worden bijgewerkt."}>
                      <InfoCircleTwoTone />
                    </Tooltip>
                    (laatst opgehaald: {this.props.schedule.kyp_schedule_updated_at ? moment(this.props.schedule.kyp_schedule_updated_at).locale('NL').format('DD-MM-YYYY HH:mm:ss') : 'nooit'})
                  </Col>
                </Row> : null }

                <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                  <Col span={24}>
                    <Select
                      showSearch
                      placeholder="Kies een KYP bouwnummer"
                      style={{ minWidth: 260 }}
                      onChange={(value) => { this.selectKypBouwnr(value) }}
                      allowClear={true}
                      name={'kyp_bouwnummer'}
                      value={this.state.kyp_bouwnummer}
                      >
                      { this.props.kyp_addresses.map((kyp_address) => {
                          return <Select.Option value={''+kyp_address}>{kyp_address}</Select.Option>
                      }) }
                    </Select>

                    <Button 
                      disabled={this.state.kyp_bouwnummer ? false : true}
                      type="new" 
                      style={{ marginLeft: 12 }}
                      onClick={() => { this.updateKypBouwnummers(); }}
                      >
                      Bijwerken
                    </Button>

                    <span>{this.state.selectedRowKeys.length} geselecteerd van de { this.state.nr_of_results }</span>
                  </Col>
                </Row>

                <Row gutter={[24, 24]}>
                  <Col span={24}>

                    <CustomTable
                      rowKey='id'
                      size="middle"
                      rowSelection={rowSelection}
                      columns={columns}
                      dataSource={this.state.addresses}
                      loading={this.props.isFetching || this.state.refresh}
                      pagination={false}
                      onChange={(pagination, filters, sorter, extra) => { 
                        
                        this.handleTableChange(pagination, filters, sorter, extra);

                        this.allChecked();
                      }}
                      footer={(data) => { 
                        
                        this.filtered_addresses = data;
                        
                        return null; 
                      }}
                      />

                  </Col>
                </Row>

            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    project: state.project.project,
    addresses: state.schedule.addresses,
    kyp_addresses: state.schedule.kyp_addresses,
    schedule: state.schedule.schedule,
    groups: state.group.groups,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleSettingsAction : bindActionCreators(updateScheduleSettingsAction, dispatch),
    // getKypProjectsAction : bindActionCreators(getKypProjectsAction, dispatch),
    getKypProjectAddressesAction : bindActionCreators(getKypProjectAddressesAction, dispatch),
    getAddressesAction: bindActionCreators(getAddressesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getProjectAction: bindActionCreators(getProjectAction, dispatch),
    refreshKypProjectDataAction: bindActionCreators(refreshKypProjectDataAction, dispatch),
    getScheduleAction: bindActionCreators(getScheduleAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleKypAddresses);
