import React, { Component } from 'react';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Select, Button, Tooltip } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../../utils/Notifications';
import ScheduleKypSteps from '../Steps/ScheduleKypSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { updateScheduleStatusAction, getSchedukeKypStatus, refreshKypProjectDataAction, getScheduleAction } from '../../../../../actions/scheduleActions';
import { getInformationAction } from '../../../../../actions/informationActions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { InfoCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';

var status_filters = [
  {
    value: '',
    text: 'Geen status toegekend'
  },
  {
    value: 'not_applicable',
    text: 'Niet van toepassing'
  },
  {
    value: 'processed',
    text: 'Verwerkt'
  }
];

class ScheduleKypStatus extends Component
{
  state = {
    selectedRowKeys: [],
  };

  componentDidMount()
  {
    this.load();
  }

  load()
  {
    this.props.getSchedukeKypStatus(this.props.match.params.id).then(() => {});

    this.props.getScheduleAction(this.props.match.params.id).then(() => {});
  }

  refreshKypProjectData()
  {
    this.props.refreshKypProjectDataAction(this.props.project.id).then((status) => {

      if(status)
      {
        showSuccess();

        this.load();
      }
      else
      {
        showError('Wacht even met het opnieuw proberen.');
      }
    });
  }

  onSelectChange = (selectedRowKeys) => {

      this.setState({ selectedRowKeys });
  }

  getStatusText(value)
  {
    if(value === ''){ return ''; }

    var text = value;

    status_filters.map((filter) => {
      if(filter.value == value){ text = filter.text; return; }
    });

    return text;
  }

  selectStatus(value)
  {
    this.setState({ selected_status: value });
  }

  updateStatus()
  {
    var values = {};

    values.ids = this.state.selectedRowKeys;
    values.status = this.state.selected_status;

    this.props.updateScheduleStatusAction(this.props.match.params.id, values).then(() => {

      showSuccess('Succesvol opgeslagen');

      this.setState({ selectedRowKeys: [] });

      this.load();
    });
  }

  render()
  {
    const columns = [
      {
        title: 'Status overzicht van verschillen tussen Cobee en KYP',
        dataIndex: 'description',
        render: (text, record) => (
          <span>{text}</span>
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filters: status_filters,
        filterMultiple: false,
        onFilter: (value, record) => { return (record.status == value || (value === '' && record.status === null)) ? true : false },
        defaultFilteredValue: [''],
        width: 180,
        render: (text, record) => (
          <span>{ this.getStatusText(text) }</span>
        )
      },
      {
        title: 'Datum en tijd',
        dataIndex: 'date',
        width: 180,
        render: (text, record) => (
          <span>{text}</span>
        )
      },
    ];

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={true} type="new" onClick={(e) => { this.updateStatus(); }}>Opslaan</Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleKypSteps current={3} {...this.props} />
          </Card>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Card>

                { this.props.project.kyp_project_id ? <Row gutter={24} style={{ marginBottom: 24 }}>
                  <Col span={24}>
                    <Button /*type={'new'}*/ style={{ /*marginRight: 12*/ }} onClick={() => { this.refreshKypProjectData(); }}>Planning ophalen</Button>
                    <Tooltip className="mhs" overlayStyle={{ whiteSpace: 'pre-line' }} title={"Met deze knop kan je de meest actuele versie van de planning in KYP ophalen en bijwerken in Cobee. De planning kan maximaal één maal per minuut worden bijgewerkt."}>
                      <InfoCircleTwoTone />
                    </Tooltip>
                    (laatst opgehaald: {this.props.schedule.kyp_schedule_updated_at ? moment(this.props.schedule.kyp_schedule_updated_at).locale('NL').format('DD-MM-YYYY HH:mm:ss') : 'nooit'})
                  </Col>
                </Row> : null }

                <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                  <Col span={24}>
                    <Select
                      placeholder="Kies een status"
                      defaultValue={false}
                      style={{ minWidth: 180 }}
                      onChange={(value) => { this.selectStatus(value) }}
                      allowClear={true}
                      >
                      { status_filters.map((filter) => {
                          return <Select.Option value={filter.value}>{filter.text}</Select.Option>
                      }) }
                    </Select>

                    <Button 
                      disabled={(this.state.selected_status || this.state.selected_status === '') && this.state.selectedRowKeys.length ? false : true}
                      type="new" 
                      style={{ marginLeft: 12 }}
                      onClick={() => { this.updateStatus(); }}
                      >
                      Opslaan
                    </Button>

                    <span>{this.state.selectedRowKeys.length} geselecteerd</span>
                  </Col>
                </Row>

                <CustomTable
                  rowKey='id'
                  size="middle"
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={this.props.kyp_status}
                  loading={this.props.isFetching || this.state.refresh}
                  pagination={false}
                  />

            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    project: state.project.project,
    information_items: state.information.information,
    kyp_status: state.schedule.kyp_status,
    schedule: state.schedule.schedule,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleStatusAction : bindActionCreators(updateScheduleStatusAction, dispatch),
    getInformationAction : bindActionCreators(getInformationAction, dispatch),
    getSchedukeKypStatus : bindActionCreators(getSchedukeKypStatus, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    refreshKypProjectDataAction: bindActionCreators(refreshKypProjectDataAction, dispatch),
    getScheduleAction: bindActionCreators(getScheduleAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleKypStatus);
