import React, { Component } from 'react';
// import '../Customers.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerAction, patchCustomerAction, addMollieAccount } from '../../../actions/customerActions';
import { showSuccess } from '../../../utils/Notifications';
import CustomerSideMenu from './CustomerSideMenu';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Switch, Divider, Input, Select } from 'antd';
import PageHeader from '../../../components/PageHeader/PageHeader';
import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';

const { Title } = Typography;
const { Option } = Select;

class CustomerConnections extends Component {
  state = {
    edit: true,
    use_personal_login_page: false,
    mailer_type: 'cobee'
  };

  componentDidMount() {
    this.props.getCustomerAction(this.props.match.params.id).then(() => {
        this.setState({ use_personal_login_page: this.props.customer.use_personal_login_page });
        this.setState({ mailer_type: this.props.customer.mailer_type });
        this.setState({ use_own_cobee_api_server: this.props.customer.api_url ? true : false });
        this.setState({ api_url: this.props.customer.api_url });
        this.setState({ use_kyp: this.props.customer.use_kyp ? true : false });
        // this.setState({ kyp_api_key: this.props.customer.kyp_api_key });
        this.setState({ kyp_login_url: this.props.customer.kyp_login_url });
    });
  }

  toggle = () => {
    this.setState({
      edit: !this.state.edit
    });
  };

  handleSubmit = e => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          var result = Object.keys(values).filter((key) => {
            if(values[key] === true) {
              return key;
            }
            return null;
          });

          this.props.patchCustomerAction(this.props.match.params.id, values /*{'use_validsign': values.use_validsign, 'validsign_api_key': values.validsign_api_key, 'mollie_api_key': values.mollie_api_key, 'use_wocoapp': values.use_wocoapp, 'mailgun_api_key': values.mailgun_api_key, 'mailgun_from_domain': values.mailgun_from_domain, 'mailgun_from_email': values.mailgun_from_email, 'mailgun_from_name': values.mailgun_from_name}*/).then(() => showSuccess());
        }
      });

  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <PageHeader path={[{'label': 'Klanten', 'link': '/customers'}, {'label': this.props.customer.name}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <CustomerSideMenu id={this.props.match.params.id} activeMenu='6' />
          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true} ref={form => this.formRef = form} >
            <Row className="mbm">
              <Col span={12} >
                <Button type="new" onClick={this.handleSubmit}>
                  Opslaan
                </Button>
                <Button onClick={() => this.props.history.push('/customers')}>Sluiten</Button>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={12}>
                <h2>ValidSign</h2>
              </Col>
              <Col span={12}>
                <h2>API key</h2>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                  <Form.Item label="">
                    {getFieldDecorator('use_validsign', {
                      valuePropName: 'checked',
                      initialValue: this.props.customer.use_validsign > 0
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
                <Col span={12}>
                  <Form.Item label="">
                    {getFieldDecorator('validsign_api_key', {
                      initialValue: this.props.customer.validsign_api_key
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <h2>Mollie API key</h2>
              </Col>
            </Row>

            <Row className="mbs" gutter={24}>
              <Col span={12}><b>Naam</b></Col>
              <Col span={12}><b>API Key</b></Col>
            </Row>
            { this.props.mollie_accounts && this.props.mollie_accounts.map((obj, index) => {
            return <Row className="mbs" gutter={24}>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('mollie_accounts['+index+']', {
                    initialValue: obj.name ? obj.name : ''
                  })(
                    <Input style={{width: '100%'}} />
                  )}
                </Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('mollie_api_keys['+index+']', {
                    initialValue: obj.mollie_api_key ? obj.mollie_api_key : ''
                  })(
                    <Input style={{width: '100%'}} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            }) }
            <Row className="mbs" gutter={24}>
              <Col span={24}>
                <Button type="dashed" onClick={() => { this.props.addMollieAccount(); }}>
                  <PlusOutlined /> Toevoegen
                </Button>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <h2>E-mails verzenden via</h2>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                  <Form.Item label="E-mailen via">
                    {getFieldDecorator('mailer_type', {
                      initialValue: this.props.customer.mailer_type,
                      onChange: (value) => { this.setState({ mailer_type: value }); }
                    })(
                      <Select>
                        <Option value="cobee">Cobee</Option>
                        <Option value="mailgun">Mailgun</Option>
                        <Option value="smtp2go">SMTP2Go</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
            </Row>

            <div style={{ display: this.state.mailer_type == 'mailgun' ? 'block' : 'none' }}>

                <Row gutter={24}>
                  <Col span={12}>
                      <Form.Item required label="API key">
                        {getFieldDecorator('mailgun_api_key', {
                          initialValue: this.props.customer.mailgun_api_key
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  <Col span={12}>
                      <Form.Item required label="Domein">
                        {getFieldDecorator('mailgun_from_domain', {
                          initialValue: this.props.customer.mailgun_from_domain
                        })(
                          <Input />
                        )}
                      </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                <Col span={12}>
                    <Form.Item required label="Naam">
                      {getFieldDecorator('mailgun_from_name', {
                        initialValue: this.props.customer.mailgun_from_name
                      })(
                        <Input />
                      )}
                    </Form.Item>
                </Col>
                  <Col span={12}>
                      <Form.Item required label="E-mail">
                        {getFieldDecorator('mailgun_from_email', {
                          initialValue: this.props.customer.mailgun_from_email
                        })(
                          <Input />
                        )}
                      </Form.Item>
                  </Col>
                </Row>

            </div>

            <div style={{ display: this.state.mailer_type == 'smtp2go' ? 'block' : 'none' }}>

                <Row gutter={24}>
                  <Col span={12}>
                      <Form.Item required label="API key">
                        {getFieldDecorator('smtp2go_api_key', {
                          initialValue: this.props.customer.smtp2go_api_key
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                <Col span={12}>
                    <Form.Item required label="Naam">
                      {getFieldDecorator('smtp2go_from_name', {
                        initialValue: this.props.customer.smtp2go_from_name
                      })(
                        <Input />
                      )}
                    </Form.Item>
                </Col>
                  <Col span={12}>
                      <Form.Item required label="E-mail">
                        {getFieldDecorator('smtp2go_from_email', {
                          initialValue: this.props.customer.smtp2go_from_email
                        })(
                          <Input />
                        )}
                      </Form.Item>
                  </Col>
                </Row>

            </div>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={12}>
                <h2>KYP</h2>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                  <Form.Item label="">
                    {getFieldDecorator('use_kyp', {
                      valuePropName: 'checked',
                      initialValue: this.state.use_kyp ? true : false,
                      onChange: (checked) => {
                          this.setState({ use_kyp: checked ? 1 : 0 });

                          if(!checked)
                          {
                              this.setState({ kyp_api_key: '' });
                          }
                      }
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              { this.state.use_kyp ?  <Col span={12}>
                {/*<Form.Item required label="KYP API key">
                  {getFieldDecorator('kyp_api_key', {
                    initialValue: this.state.kyp_api_key,
                    onChange: (e) => { this.setState({ kyp_api_key: e.target.value }); }
                  })(
                    <Input value={this.state.kyp_api_key} />
                  )}
                </Form.Item>*/}
                <Form.Item required label="KYP login url">
                  {getFieldDecorator('kyp_login_url', {
                    initialValue: this.state.kyp_login_url,
                    onChange: (e) => { this.setState({ kyp_login_url: e.target.value }); }
                  })(
                    <Input value={this.state.kyp_login_url} />
                  )}
                </Form.Item>
              </Col> : null }
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={12}>
                <h2>Woco APP</h2>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                  <Form.Item label="">
                    {getFieldDecorator('use_wocoapp', {
                      valuePropName: 'checked',
                      initialValue: this.props.customer.use_wocoapp > 0
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={12}>
                <h2>Cobee API server</h2>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                  <Form.Item label="">
                    {getFieldDecorator('use_own_cobee_api_server', {
                      valuePropName: 'checked',
                      initialValue: this.state.use_own_cobee_api_server ? true : false,
                      onChange: (checked) => {
                          this.setState({ use_own_cobee_api_server: checked });

                          if(!checked)
                          {
                              this.setState({ api_url: '' });
                          }
                      }
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              { this.state.use_own_cobee_api_server ?  <Col span={12}>
                <Form.Item required label="API server url">
                  {getFieldDecorator('api_url', {
                    initialValue: this.state.api_url,
                    onChange: (e) => { this.setState({ api_url: e.target.value }); }
                  })(
                    <Input value={this.state.api_url} />
                  )}
                </Form.Item>
              </Col> : null }
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const CustomerConnectionsForm = Form.create({ name: 'customer_connections' })(CustomerConnections);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customer: state.customer.customer,
    mollie_accounts: state.customer.mollie_accounts
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    patchCustomerAction : bindActionCreators(patchCustomerAction, dispatch),
    addMollieAccount : bindActionCreators(addMollieAccount, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerConnectionsForm);
