import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './Standalone.css';
import Logo from '../../assets/img/logo_zwart.svg';
import { Layout, Col, Row, Button } from 'antd';
import queryString from 'query-string';
import { saveKypAccessTokenAction } from '../../actions/scheduleActions';

class KypOauth extends Component {

  state = {
    kyp_access_token: null,
    error: null
  }

  componentDidMount()
  {
    var input_vars = queryString.parse(this.props.location.search);

    if(input_vars.access_token)
    {
      this.setState({ kyp_access_token: input_vars.access_token }, () => {

        // store access token in project
        this.props.saveKypAccessTokenAction(window.sessionStorage.getItem('project_id_for_kyp'), input_vars.access_token).then((response) => {

          if(response == true)
          {
            window.location.href = window.sessionStorage.getItem('kyp_return_url');
          }
          else
          {
            this.setState({ error: 'De toegangsgegevens konden niet worden opgeslagen in Cobee. Probeer het later nog eens.' });
          }
        });
      });
    }
    else
    {
      this.setState({ kyp_access_token: false });
    }
  }

  render()
  {
    return (
      <div className="StandaloneScreen" style={{ maxHeight: '100vh', overflow: 'hidden', width: '100%' }}>

        <Layout style={{ backgroundColor: 'white' }}>

          <Row gutter={24} style={{ minHeight: '100vh' }} type="flex" align="middle">

            <Col span={24} type="flex" align="middle">
            
              <div style={{ maxWidth: '500px', padding: 24 }}>

                <img width="200px" height="auto" alt="Cobee" src={Logo} style={{ width: 200, marginBottom: 50 }}></img><br />

                <h2>KYP koppelen aan Cobee project</h2>

                {/*<span style={{ fontSize: 20, fontWeight: 'bold'}}>TODO: KYP OAUTH handling</span><br />*/}

                { this.state.kyp_access_token === null ? <span style={{ fontSize: 14, fontWeight: 'bold'}}>Bezig met het ophalen van de KYP toegangsgegevens...</span> : null }
                { this.state.kyp_access_token === false ? <span style={{ fontSize: 14, fontWeight: 'bold'}}>De toegangsgegevens tot KYP konden niet worden opgehaald. Probeer het later nog eens.</span> : null }
                { this.state.kyp_access_token !== null && this.state.kyp_access_token !== false ? <span style={{ fontSize: 14, fontWeight: 'bold'}}>De toegangsgegevens tot KYP zijn opgehaald en worden nu gekoppeld aan het Cobee project.<br/><br/>Je wordt straks automatisch doorverwezen.</span> : null }                
                { this.state.error ? <span style={{ clear: 'both', marginTop: 16, fontSize: 14, fontWeight: 'bold', color: 'red'}}>{this.state.error}</span> : null }

              </div>

            </Col>

          </Row>

        </Layout>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {    
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveKypAccessTokenAction: bindActionCreators(saveKypAccessTokenAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(KypOauth);
